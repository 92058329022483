export function noPaddingPage() {
    document.body.classList.add("no-padding")
}

export function withPaddingPage() {
    document.body.classList.remove("no-padding")
}

export function getDateAsYYYYMMDDString(date) {
    let year = "" + (date.getFullYear())
    let month = "" + (date.getMonth() + 1)
    let day = "" + date.getDate()

    return [year, month.length === 2 ? month : "0" + month, day.length === 2 ? day : "0" + day].join("-")
}

export function getDateAsYearMonthDay(date) {
    return [parseInt(date.getFullYear()), parseInt(date.getMonth() + 1), parseInt(date.getDate())]
}

export function formatCurrency(amount) {
    return amount
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export function currencyToNumber(currency) {
    return parseInt(currency.replace(".", "").replace(",00", ""))
}

export function addDeliveryPrice(price, deliveryPrice, freeDeliveryPriceLimit) {
    if (price < freeDeliveryPriceLimit) {
        return price + deliveryPrice
    } else {
        return price
    }
}

export function getMealCategoryName(index) {
    switch (index) {
        case 1:
            return "Supe i čorbe"
        case 2:
            return "Meso sa prilogom"
        case 3:
            return "Jela kašikom"
        case 4:
            return "Testenina"
        case 5:
            return "Salata 1"
        case 6:
            return "Hleb"
        case 7:
            return "Jela bez mesa"
        case 8:
            return "Salata 2"
        case 9:
            return "Hrono obrok"
        case 10:
            return "Obrok salata"
        case 11:
            return "Desert"
    }
}

export function getMealOptionWeight(category, size) {
    switch (category) {
        case 1:
            switch (size) {
                case 1:
                    return "250 ml"
                case 2:
                    return "375 ml"
                case 4:
                    return "500 ml"
                default:
                    return undefined
            }
        case 2:
            switch (size) {
                case 1:
                    return "320 - 350 g"
                case 2:
                    return "450 - 500 g"
                case 4:
                    return "650 - 700 g"
                default:
                    return undefined
            }
        case 9:
            switch (size) {
                case 1:
                    return "320 - 350 g"
                case 2:
                    return "450 - 500 g"
                case 4:
                    return "650 - 700 g"
                default:
                    return undefined
            }
        case 3:
            switch (size) {
                case 1:
                    return "350 g"
                case 2:
                    return "500 g"
                case 4:
                    return "700 g"
                default:
                    return undefined
            }
        case 4:
            switch (size) {
                case 1:
                    return "350 g"
                case 2:
                    return "500 g"
                case 4:
                    return "700 g"
                default:
                    return undefined
            }
        case 10:
            switch (size) {
                case 3:
                    return "350 - 400 g"
                default:
                    return undefined
            }
        case 5:
            switch (size) {
                case 3:
                    return "150 g"
                default:
                    return undefined
            }
        case 8:
            switch (size) {
                case 3:
                    return "150 g"
                default:
                    return undefined
            }
        default:
            return undefined
    }
}

export function checkAuthentication(response) {
    if (response === "nijeulogovan") {
        window.location.href = "/"
    }

    return response
}

export function setFinalDeliveryTime(response) {

    // home delivery is until 9h
    if (response.homeDelivery) {
        response.finalTime = 9
    }

    return response
}
