import React, { useState, useEffect } from 'react'
import Menu from '../components/Menu'
import Header from '../components/Header'
import Empty from '../components/Empty'
import { withPaddingPage, getMealCategoryName } from '../config/Util'
import { OrderService } from '../services/OrderService'
import { HomeOrderService } from '../services/HomeOrderService'
import Loader from '../components/Loader'
import Category from '../components/Category'
import Meal from '../components/Meal'
import Strings from '../config/Strings'
import MakeOrder from '../components/MakeOrder'
import { useHistory, useLocation } from 'react-router-dom'
import Dialog from '../components/Dialog'
import ReactRoutes from '../config/ReactRoutes'
import DeliveryTimeDialog from '../components/DeliveryTimeDialog'

function Day(props) {

    withPaddingPage()

    const [isLoaded, setIsLoaded] = useState(false)
    const [date, setDate] = useState(undefined)
    const [meals, setMeals] = useState([])
    const [stockStatuses, setStockStatuses] = useState([])
    const [shoppingCart, setShoppingCart] = useState([])
    const [shoppingCartTotal, setShoppingCartTotal] = useState(0)
    const [showDialog, setShowDialog] = useState(false)
    const [dialogData, setDialogData] = useState({})
    const [showErrorDialog, setShowErrorDialog] = useState(false)
    const [errorDialogData, setErrorDialogData] = useState({})
    const [cartFromUrl, setCartFromUrl] = useState([])
    const [orderInProgress, setOrderInProgress] = useState(false)
    const [showDeliveryTimeDialog, setShowDeliveryTimeDialog] = useState(false)

    const history = useHistory()
    const query = new URLSearchParams(useLocation().search)
    const isHomeDelivery = props.match.params.homeDelivery === "true" ? true : false

    useEffect(() => {
        let date = new Date(Date.parse(props.match.params.year + "-" + (props.match.params.month.length === 2 ? props.match.params.month : "0" + props.match.params.month) + "-" + (props.match.params.day.length === 2 ? props.match.params.day : "0" + props.match.params.day)))
        setDate(date)

        Promise.all([
            OrderService.getMealsForDay(date),
            OrderService.getStockStatus(date)
        ])
        .then(([meals, stockStatuses]) => {
            setMeals(meals)
            setStockStatuses(stockStatuses)
            getCartFromUrl(meals)
            setIsLoaded(true)
        })
        .catch(error => {
            console.log(error)
        })

    }, [])

    function getCartFromUrl(meals) {
        if (query.get("cart") !== null) {
            let cartFromUrl = JSON.parse(atob(query.get("cart")))
            setCartFromUrl(cartFromUrl)

            let tempShoppingCart = []

            for (let cartFromUrlItem of cartFromUrl) {
                let tempShoppingCartItem = [parseInt(cartFromUrlItem[0]), parseInt(cartFromUrlItem[1]), parseInt(cartFromUrlItem[2])]

                let tempShoppingCartItemPrice = 0
                for (let meal of meals) {
                    if (parseInt(meal[0]) === tempShoppingCartItem[0]) {
                        switch (tempShoppingCartItem[1]) {
                            case 1: // small
                                tempShoppingCartItemPrice = parseInt(meal[5])
                                break
                            case 2: // big
                                tempShoppingCartItemPrice = parseInt(meal[6])
                                break
                            case 3: // default
                                tempShoppingCartItemPrice = parseInt(meal[7])
                                break
                            case 4: // xl
                                tempShoppingCartItemPrice = parseInt(meal[9])
                                break
                        }
                    }
                }

                tempShoppingCartItem.push(tempShoppingCartItemPrice)

                if (tempShoppingCartItem[2] === 11) {
                    tempShoppingCartItem[4] = tempShoppingCartItem[3] // backup dessert price
                }

                tempShoppingCart.push(tempShoppingCartItem)
            }

            let [discount30, discount50] = calculateDessertDiscount(tempShoppingCart)

            for (let i = 0; i < tempShoppingCart.length; i++) {
                if (tempShoppingCart[i][2] === 11) {
                    if (discount50) {
                        tempShoppingCart[i][3] = Math.floor((tempShoppingCart[i][4] / 100) * 50)
                    } else if (discount30) {
                        tempShoppingCart[i][3] = Math.floor((tempShoppingCart[i][4] / 100) * 70)
                    } else {
                        tempShoppingCart[i][3] =  tempShoppingCart[i][4]
                    }
                }
            }

            setShoppingCart(tempShoppingCart)
        }
    }

    useEffect(() => {
        updateShoppingCartTotal(shoppingCart.reduce((total, cartItem) => total + cartItem[3], 0))

        if (shoppingCart.length === 0 && orderInProgress === true) {
            OrderService.removeOrderInProgress()
            setOrderInProgress(false)
        } else if (shoppingCart.length !== 0 && orderInProgress === false) {
            OrderService.setOrderInProgress(date)
            setOrderInProgress(true)
        }
    }, [shoppingCart])

    function updateShoppingCart(item) {
        let tempShoppingCart = shoppingCart

        if (item[2] === 11) {
            item[4] = item[3] // backup dessert price
        }

        if (item[1] === undefined) {
            tempShoppingCart = tempShoppingCart.filter(cartItem => cartItem[0] !== item[0])
        } else {
            if (tempShoppingCart.find(cartItem => cartItem[0] === item[0]) !== undefined) {
                tempShoppingCart = tempShoppingCart.filter(cartItem => cartItem[0] !== item[0])
            }
            tempShoppingCart = [...tempShoppingCart, item]
        }

        let [discount30, discount50] = calculateDessertDiscount(tempShoppingCart)

        for (let i = 0; i < tempShoppingCart.length; i++) {
            if (tempShoppingCart[i][2] === 11) {
                if (discount50) {
                    tempShoppingCart[i][3] = Math.floor((tempShoppingCart[i][4] / 100) * 50)
                } else if (discount30) {
                    tempShoppingCart[i][3] = Math.floor((tempShoppingCart[i][4] / 100) * 70)
                } else {
                    tempShoppingCart[i][3] =  tempShoppingCart[i][4]
                }
            }
        }

        setShoppingCart(tempShoppingCart)
    }

    function updateShoppingCartTotal(shoppingCartTotalWithoutDelivery) {
        let deliveryPrice = 0
        
        // calculate and add home delivery price
        // if (isHomeDelivery) {
        //     if (shoppingCartTotalWithoutDelivery < 1000) {
        //         deliveryPrice = 220
        //     } else if (shoppingCartTotalWithoutDelivery >= 1000 && shoppingCartTotalWithoutDelivery < 1500) {
        //         deliveryPrice = 120
        //     }
        // }

        setShoppingCartTotal(shoppingCartTotalWithoutDelivery + deliveryPrice)
    }

    function calculateDessertDiscount(shoppingCart) {
        let discount30 = false
        let discount50 = false

        for (let cartItem of shoppingCart) {
            if (cartItem[2] === 2 || cartItem[2] === 3 || cartItem[2] === 4 || cartItem[2] === 7 || cartItem[2] === 9){
                if (cartItem[1] === 1){
                    discount30 = true;
                }
    
                if (cartItem[1] === 2 || cartItem[1] === 4){
                    discount50 = true;
                    break
                }
            }
    
            if (cartItem[2] === 10 && cartItem[1] === 3){
                discount50 = true;
                break
            }
        }

        return [discount30, discount50]
    }

    function chooseDeliveryTime() {
        setShowDeliveryTimeDialog(true)
    }

    function makeNewOrder() {
        OrderService.makeNewOrder(shoppingCart, date)
            .then(response => {
                if (response === "uspesnoporucen") {
                    setDialogData({
						title: Strings.ORDER_SUCCESSFUL
                    })
                    
                    setShowDialog(true)
                } else {
                    setErrorDialogData({
                        title: Strings.ERROR,
                        message: response
                    })
                    
                    setShowErrorDialog(true)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    function homeMakeNewOrder(deliveryTime) {
        HomeOrderService.makeNewOrder(shoppingCart, date, deliveryTime)
            .then(response => {
                if (response === "uspesnoporucen") {
                    setDialogData({
						title: Strings.ORDER_SUCCESSFUL
                    })
                    
                    setShowDialog(true)
                } else {
                    setErrorDialogData({
                        title: Strings.ERROR,
                        message: response
                    })
                    
                    setShowErrorDialog(true)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    function saveEditedOrder() {
        OrderService.saveEditedOrder(parseInt(query.get("orderId")), shoppingCart)
            .then(response => {
                if (response === "uspesnoizmenjen") {
                    setDialogData({
						title: Strings.ORDER_EDITED
                    })
                    
                    setShowDialog(true)
                } else {
                    setErrorDialogData({
                        title: Strings.ERROR,
                        message: response
                    })
                    
                    setShowErrorDialog(true)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    function homeSaveEditedOrder(deliveryTime) {
        HomeOrderService.saveEditedOrder(parseInt(query.get("orderId")), shoppingCart, deliveryTime)
            .then(response => {
                if (response === "uspesnoizmenjen") {
                    setDialogData({
						title: Strings.ORDER_EDITED
                    })
                    
                    setShowDialog(true)
                } else {
                    setErrorDialogData({
                        title: Strings.ERROR,
                        message: response
                    })
                    
                    setShowErrorDialog(true)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    function isEdit() {
        return cartFromUrl.length === 0 ? false : true
    }

    function processOrder(deliveryTime = null) {
        if (isHomeDelivery) {
            if (isEdit()) {
                homeSaveEditedOrder(deliveryTime)
            } else {
                homeMakeNewOrder(deliveryTime)
            }
        } else {
            if (isEdit()) {
                saveEditedOrder()
            } else {
                makeNewOrder()
            }
        }
    }

    function dayIsEmpty() {
        return <Empty text={Strings.DAY_MENU_IS_EMPTY} />
    }

    function dayIsLoading() {
        return <Loader />
    }

    function setMakeOrder() {
        if (shoppingCart.length !== 0) {
            if (!isHomeDelivery) {
                return <MakeOrder isEdit={isEdit()} isHomeDelivery={isHomeDelivery} onClick={processOrder} total={shoppingCartTotal} />
            } else {
                return <MakeOrder isEdit={isEdit()} isHomeDelivery={isHomeDelivery} onClick={chooseDeliveryTime} total={shoppingCartTotal} />
            }
        }
    }

    function printMeals() {
        let output = []

        let mealCategoriesOrdered = [
            "Supe_i_čorbe",
            "Meso_sa_prilogom",
            "Jela_kašikom",
            "Testenina",
            "Jela_bez_mesa",
            "Hrono_obrok",
            "Obrok_salata",
            "Salata_1",
            "Salata_2",
            "Hleb",
            "Desert"
        ]

        let mealsObj = {}

        meals.forEach(meal => {
            let mealCategory = parseInt(meal[8])
            let mealCategoryName = getMealCategoryName(mealCategory).split(" ").join("_")

            if (mealsObj[mealCategoryName] === undefined) {
                mealsObj[mealCategoryName] = [meal]
            } else {
                mealsObj[mealCategoryName].push(meal)
            }
        })

        for (let mealCategory of mealCategoriesOrdered) {
            if (mealsObj[mealCategory] === undefined)
                continue
            
            let mealCategoryName = mealCategory.split("_").join(" ")
            mealCategoryName = mealCategoryName === "Obrok salata" ? "Obrok salate / sendviči / buritosi / poke" : mealCategoryName
            mealCategoryName = mealCategoryName === "Hrono obrok" ? "Hrono / Vege / Fit" : mealCategoryName
            output.push(<Category key={mealCategory} name={mealCategoryName} />)

            mealsObj[mealCategory].forEach(meal => {
                output.push(
                    <Meal
                        selectedOptionInitial={setSelectedOptionInitial(parseInt(meal[0]))}
                        selectedOptionChange={updateShoppingCart}
                        key={parseInt(meal[0])}
                        id={parseInt(meal[0])}
                        name={meal[1]}
                        description={meal[2]}
                        image={meal[4]}
                        posno={meal[3] === "da" ? true : false}
                        spicy={meal[10] === "da" ? true : false}
                        vege={meal[11] === "da" ? true : false}
                        hrono={meal[12] === "da" ? true : false}
                        fit={meal[13] === "da" ? true : false}
                        small={parseInt(meal[5])}
                        big={parseInt(meal[6])}
                        xl={parseInt(meal[9])}
                        default={parseInt(meal[7])}
                        category={parseInt(meal[8])}
                        onStock={getStockStatusForMealId(meal[0])}
                        percentageToShow={percentageToShow(mealCategory)}
                    />
                )
            })

        }

        return output
    }

    function setSelectedOptionInitial(meailId) {
        for (let cartItem of cartFromUrl) {
            if (parseInt(cartItem[0]) === meailId) {
                return parseInt(cartItem[1])
            }
        }
        return undefined
    }

    function percentageToShow(mealCategory) {
        if (mealCategory !== getMealCategoryName(11)) {
            return 100
        } else {
            let [discount30, discount50] = calculateDessertDiscount(shoppingCart)

            if (discount50) {
                return 50
            }

            if (discount30) {
                return 70
            }

            return 100
        }
    }

    function getStockStatusForMealId(id) {
        for (let stockStatus of stockStatuses) {
            if (stockStatus[0] === id) {
                return parseInt(stockStatus[1])
            }
        }
    }

	return (
        <div>
            <Header back={ () => history.goBack() } text={props.match.params.title + " " + props.match.params.day + "/" + props.match.params.month} />
            
            {
                isLoaded ? (
                    meals.length === 0 ? (
                        dayIsEmpty()
                    ) : (
                        printMeals()
                    )
                ) : (
                    dayIsLoading()
                )
            }

            {setMakeOrder()}

            <Menu active="home" />
            {
                showDeliveryTimeDialog ? <DeliveryTimeDialog primaryAction={ (e) => { processOrder(e); setShowDeliveryTimeDialog(false) } } isEdit={isEdit()} closeDialog={ () => setShowDeliveryTimeDialog(false) } /> : null
            }

            {
				showDialog ? <Dialog primaryText={Strings.OK} primaryAction={ () => { setShowDialog(false); history.push(ReactRoutes.MY_ORDERS) } } data={dialogData} /> : null
			}

            {
                showErrorDialog ? <Dialog primaryText={Strings.OK} primaryAction={ () => setShowErrorDialog(false) } data={errorDialogData} /> : null
            }
        </div>
	)
}

export default Day
