import ApiRoutes from '../config/ApiRoutes'

export const LogInRegisterService = {
    logIn: function(telephone, password, rememberMe) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("brtelefona", telephone)
            formData.append("sifra", password)
            formData.append("zapamtime", rememberMe)

            fetch(ApiRoutes.LOGIN, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    getAllCompanies: function() {
        return new Promise((resolve, reject) => {

            fetch(ApiRoutes.GET_ALL_COMPANIES)
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.json()
                }
            })
            .then(data => {
                data = Object.entries(data)
                data = data.sort((a, b) => {
                    if (a[1].trim() > b[1].trim()) return 1
                    if (a[1].trim() < b[1].trim()) return -1
                    return 0
                })

                resolve(data)
            })
            .catch(error => reject(error))

        })
    },

    getCompanySectors: function(companyId) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("ustanovaid", companyId)

            fetch(ApiRoutes.GET_COMPANY_SECTORS, {
                method: "POST",
                body: formData
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.json()
                }
            })
            .then(data => {
                data = Object.entries(data)

                resolve(data)
            })
            .catch(error => reject(error))

        })
    },

    register: function(company, sector, name, lastName, telephone, password, confirmPassword, email) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("ustanova", company)
            formData.append("odeljenje", sector)
            formData.append("ime", name)
            formData.append("prezime", lastName)
            formData.append("brtel", telephone)
            formData.append("sifra", password)
            formData.append("sifra2", confirmPassword)
            formData.append("email", email)

            fetch(ApiRoutes.REGISTER, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    getTermsAndConditions: function() {
        return new Promise((resolve, reject) => {

            fetch(ApiRoutes.TERMS_AND_CONDITIONS)
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    resetPasswordViaTelephone: function(telephone) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("brtelefonazab", telephone)

            fetch(ApiRoutes.RESET_PASSWORD_VIA_TELEPHONE, {
                method: "POST",
                body: formData
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    resetPasswordViaEmail: function(email) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("emailzab", email)

            fetch(ApiRoutes.RESET_PASSWORD_VIA_EMAIL, {
                method: "POST",
                body: formData
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    }
}
