import React, { useEffect, useState } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import { withPaddingPage } from '../../config/Util'
import Strings from '../../config/Strings'
import AccountInfo from '../../components/AccountInfo'
import { useHistory } from 'react-router-dom'
import { AccountService } from '../../services/AccountService'
import Dialog from '../../components/Dialog'
import ReactRoutes from '../../config/ReactRoutes'

function ChangeSector(props) {

    withPaddingPage()

    const [selectedSector, setSelectedSector] = useState(props.sessionData.sectorId)
    const [yourPassword, setYourPassword] = useState("")
    const [showDialog, setShowDialog] = useState(false)
    const [dialogData, setDialogData] = useState({})
    const [showErrorDialog, setShowErrorDialog] = useState(false)
    const [errorDialogData, setErrorDialogData] = useState({})

    const history = useHistory()

    useEffect(() => {

    }, [])

    function changeSector(e) {
        if (validateInput()) {
            let targetClassList = e.target.classList;
            targetClassList.add("spinner")

            AccountService.changeSector(yourPassword, selectedSector)
                .then(response => {
                    if (response === "novoodeljenjeuspesno") {
                        setDialogData({
                            title: Strings.SECTOR_CHANGED
                        })
                        setShowDialog(true)
                        props.triggerRefreshSessionData()
                    } else {
                        setErrorDialogData({
                            title: response
                        })
                        setShowErrorDialog(true)
                    }
                    targetClassList.remove("spinner")
                })
                .catch(error => {
                    console.log(error)
                    targetClassList.remove("spinner")
                })
        }
    }

    function validateInput() {
        let valid = true

        if (!selectedSector || !yourPassword) {
            valid = false
            setErrorDialogData({
                title: Strings.ERROR_MUST_FILL_ALL_FIELDS
            })
        }

        setShowErrorDialog(!valid)
        return valid
    }

	return (
        <div>
            <Header back={ () => history.goBack() } text={Strings.CHANGE_SECTOR} />
            <AccountInfo
                name={props.sessionData.name}
                lastName={props.sessionData.lastName}
                telephone={props.sessionData.telephone}
                email={props.sessionData.email}
                companyName={props.sessionData.companyName}   
            />

            <select onChange={ (e) => setSelectedSector(e.target.value) } className="input show-animation" defaultValue={props.sessionData.sectorId}>
                {
                    props.sessionData.allSectors && props.sessionData.allSectors.map(sector => {
                        return <option key={sector["id"]} value={sector["id"]}>{sector["naziv"]}</option>
                    })
                }
            </select>

			<input onChange={ (e) => setYourPassword(e.target.value) } className="input show-animation" placeholder="Vaša lozinka" type="password" />

            <button onClick={changeSector} className="btn btn-green mt2rem show-animation">{Strings.SAVE}</button>

            <Menu active="account" />

            {
				showDialog ? <Dialog primaryText={Strings.OK} primaryAction={ () => history.push(ReactRoutes.ACCOUNT) } data={dialogData} /> : null
			}

            {
				showErrorDialog ? <Dialog primaryText={Strings.OK} primaryAction={ () => setShowErrorDialog(false) } data={errorDialogData} /> : null
			}
        </div>
	)
}

export default ChangeSector
