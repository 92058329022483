const ReactRoutes = []

ReactRoutes.INDEX = ""
ReactRoutes.LOGIN = "/login"
ReactRoutes.REGISTER = "/register"
ReactRoutes.FORGOTTEN_PASSWORD = "/forgotten-password"
ReactRoutes.HOME = "/home"
ReactRoutes.DAY = "/day/:title/:day/:month/:year/:homeDelivery"
ReactRoutes.MY_ORDERS = "/my-orders"
ReactRoutes.ORDERS_HISTORY = "/history"
ReactRoutes.FEEDBACK = "/feedback"
ReactRoutes.ACCOUNT = "/account"
ReactRoutes.SERPICA_HOME_SETTINGS = ReactRoutes.ACCOUNT + "/home-settings"
ReactRoutes.CHANGE_PASSWORD = ReactRoutes.ACCOUNT + "/change-password"
ReactRoutes.CHANGE_EMAIL = ReactRoutes.ACCOUNT + "/change-email"
ReactRoutes.DEACTIVATE_ACCOUNT = ReactRoutes.ACCOUNT + "/deactivate"
ReactRoutes.CHANGE_SECTOR = ReactRoutes.ACCOUNT + "/change-sector"
ReactRoutes.CHANGE_COMPANY = ReactRoutes.ACCOUNT + "/change-company"

export default ReactRoutes