import React, { useEffect, useState } from 'react'
import { noPaddingPage } from '../config/Util'
import { useHistory } from 'react-router-dom'
import Dialog from '../components/Dialog'
import { LogInRegisterService } from '../services/LogInRegisterService'
import ReactRoutes from '../config/ReactRoutes'
import Strings from '../config/Strings'
import { AccountService } from '../services/AccountService'

function Register(props) {

    noPaddingPage()
    
    const history = useHistory()

    const [allCompanies, setAllCompanies] = useState([])
    const [companySectors, setCompanySectors] = useState([])

    const [selectedCompany, setSelectedCompany] = useState("")
    const [selectedSector, setSelectedSector] = useState("")
    const [name, setName] = useState("")
    const [lastName, setLastName] = useState("")
    const [telephone, setTelephone] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [email, setEmail] = useState("")
    // const [termsAndConditions, setTermsAndConditions] = useState("")
    // const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false)

    const [showDialog, setShowDialog] = useState(false)
	const [dialogData, setDialogData] = useState({})

    function getCompanySectors(e) {
        let companyId = e.target.value
        setSelectedCompany(companyId)

        LogInRegisterService.getCompanySectors(companyId)
            .then(response => setCompanySectors(response))
            .catch(error => console.log(error))
    }

    function validateInput() {
        let valid = true

        if (!selectedCompany || !selectedSector || !name || !lastName || !telephone || !password || !confirmPassword) {
            valid = false
            setDialogData({
                title: Strings.ERROR_MUST_FILL_ALL_FIELDS_REGISTER
            })
        } else if (password !== confirmPassword) {
            valid = false
            setDialogData({
                title: Strings.PASSWORDS_DO_NOT_MATCH
            })
        }
        // } else if (!acceptTermsAndConditions) {
        //     valid = false
        //     setDialogData({
        //         title: Strings.ERROR_ACCEPT_TERMS_AND_CONDITIONS
        //     })
        // }

        setShowDialog(!valid)
        return valid
    }

    function register(e) {
        if (validateInput()) {
            let targetClassList = e.target.classList;
            targetClassList.add("spinner")

            LogInRegisterService.register(selectedCompany, selectedSector, name, lastName, telephone, password, confirmPassword, email)
                .then(response => {
                    if (response === "registrovan") {
                        AccountService.getSessionData()
                            .then(sessionData => {
                                props.setSessionData(sessionData)
                                history.push(ReactRoutes.HOME)
                            })
                            .catch(error => {
                                console.log(error)
                                targetClassList.remove("spinner")
                            })
                    } else {
                        targetClassList.remove("spinner")
                        setDialogData({
                            title: response
                        })
                        setShowDialog(true)
                    }
                })
                .catch(error => {
                    console.log(error)
                    targetClassList.remove("spinner")
                })
        }
    }

    useEffect(() => {
        props.setDemoAccountNotificationRead(false)
        LogInRegisterService.getAllCompanies()
        .then(companies => setAllCompanies(companies))
        .catch(error => {
            console.log(error)
        })
    }, [])

    // useEffect(() => {
    //     props.setDemoAccountNotificationRead(false)
    //     Promise.all([
    //         LogInRegisterService.getAllCompanies(),
    //         LogInRegisterService.getTermsAndConditions()
    //     ])
    //     .then(([companies, termsAndConditions]) => {
    //         setAllCompanies(companies)
    //         setTermsAndConditions(termsAndConditions)
    //     })
    //     .catch(error => {
    //         console.log(error)
    //     })
    // }, [])

    // function showTermsAndConditions(e) {
    //     e.preventDefault()
    //     setDialogData({
    //         title: Strings.TERMS_AND_CONDITIONS,
    //         message: termsAndConditions
    //     })
    //     setShowDialog(true)
    // }

	return (
		<div className="show-animation" style={{ overflow: "auto" }}>
            <img src="assets/img/logo.png" className="logo" alt="logo" />

            <select onChange={getCompanySectors} className="input" defaultValue="">
                <option disabled value="">Izaberite ustanovu</option>
                {
                    allCompanies.map(company => {
                        return <option key={company[0]} value={company[0]}>{company[1]}</option>
                    })
                }
            </select>
            
            <select onChange={ (e) => setSelectedSector(e.target.value) } className="input" defaultValue="">
                <option disabled value="">Izaberite odeljenje</option>
                {
                    companySectors.map(sector => {
                        return <option key={sector[0]} value={sector[0]}>{sector[1]}</option>
                    })
                }
            </select>

            <input onChange={ (e) => setName(e.target.value) } type="text" className="input" placeholder="Vaše ime" />
            <input onChange={ (e) => setLastName(e.target.value) } type="text" className="input" placeholder="Vaše prezime" />
            <input onChange={ (e) => setTelephone(e.target.value) } className="input" placeholder="Vaš broj telefona" type="tel" />
            <input onChange={ (e) => setPassword(e.target.value) } className="input" placeholder="Vaša lozinka" type="password" />
            <input onChange={ (e) => setConfirmPassword(e.target.value) } className="input" placeholder="Potvrdite lozinku" type="password" />
            <input onChange={ (e) => setEmail(e.target.value) } className="input" placeholder="Vaš email" type="email" />

            {/* <div className="check-wrapper">
                <input onChange={ (e) => setAcceptTermsAndConditions(e.target.checked) } className="check" type="checkbox" id="termsandconditionscheckbox" />
                <label htmlFor="termsandconditionscheckbox">prihvatam <a className="inline-link" href="#" onClick={showTermsAndConditions}>uslove korišćenja</a></label>
            </div> */}

            <button onClick={register} className="btn btn-green">Otvori nalog</button>

            <button onClick={ () => history.push("/login") } className="btn mt3rem">Prijavi se</button>

            {
				showDialog ? <Dialog primaryText={Strings.OK} primaryAction={ () => setShowDialog(false) } data={dialogData} /> : null
			}

		</div>
	)
}

export default Register
