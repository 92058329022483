import React from 'react'

function Category(props) {

	return (
    	<div className="category show-animation">{props.name}</div>
	)
}

export default Category
