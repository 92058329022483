import React from 'react'
import {formatCurrency} from "../config/Util";

function HistoryDay(props) {

    function setDate() {
        if (props.date === undefined)
            return

        let [, month, day] = props.date.split("-")

        return day + ' / ' + month
    }

    function setCompanyDiscount() {
        if (props.companyDiscount === undefined)
            return

        if (props.companyDiscount === 0)
            return

        return <div className="item green">
            Firma platila: {formatCurrency(props.companyDiscount)}
        </div>
    }

    return (
        <div className="history-day show-animation">
            <div className="info">
                <div className="item">
                    Dan {setDate()}
                </div>

                <div className="item">
                    Ukupno: {formatCurrency(props.total)}
                </div>

                {setCompanyDiscount()}
            </div>
            {props.children}
        </div>
    )
}

export default HistoryDay
