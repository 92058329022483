const Strings = []

Strings.ERROR_ACCEPT_TERMS_AND_CONDITIONS = "Morate prihvatiti uslove korišćenja!"
Strings.TERMS_AND_CONDITIONS = "Uslovi korišćenja"
Strings.ERROR_MUST_FILL_ALL_FIELDS_REGISTER = "Morate popuniti sva polja sem E-mail adrese!"
Strings.ERROR_MUST_FILL_ALL_FIELDS_LOGIN = "Morate popuniti sva polja!"
Strings.ERROR_MUST_FILL_ONE_FIELD = "Morate popuniti jedno od ponuđenih polja!"
Strings.ERROR_MUST_FILL_ALL_FIELDS = "Morate popuniti sva polja!"
Strings.ERROR_MUST_FILL_FEEDBACK_MESSAGE = "Morate uneti Vaš utisak, komentar, sugestiju..."
Strings.ERROR_MUST_FILL_STREET_ADDRESS = "Morate uneti ulicu i broj!"
Strings.ERROR_MUST_FILL_PASSWORD = "Morate uneti Vašu lozinku!"

Strings.FORGOTTEN_PASSWORD_SUCCESSFUL_TELEPPHONE = "Zahtev uspešno podnet! Uskoro ćemo Vas kontaktirati."
Strings.FORGOTTEN_PASSWORD_SUCCESSFUL_EMAIL = "Zahtev uspešno podnet! Poslali smo Vam E-mail sa linkom za promenu lozinke."

Strings.PAGE_HOME = "Dobrodošao/la "
Strings.PAGE_MY_ORDERS = "Moje porudžbine"
Strings.PAGE_HISTORY = "Istorija poručivanja"
Strings.PAGE_FEEDBACK = "Vaš utisak o Šerpici"

Strings.DAY_NAMES_ARRAY = ["nedelja", "ponedeljak", "utorak", "sreda", "četvrtak", "petak", "subota"]
Strings.TODAY = "danas"
Strings.TOTAL = "Ukupno: "
Strings.TOTAL_WITHOUT_DELIVERY = "Ukupno bez dostave: "
Strings.MAKE_ORDER = "Poruči"
Strings.CHOOSE_DELIVERY_TIME = "Izaberi termin dostave"
Strings.SAVE = "Sačuvaj"
Strings.DEACTIVATE = "Deaktiviraj"
Strings.DELETE = "Obriši"
Strings.CANCEL = "Odustani"
Strings.OK = "U redu"
Strings.ERROR = "Greška"
Strings.SOLD = "Rasprodato"

Strings.THANKS_FOR_FEEDBACK = "Utisak uspešno poslat! Šerpica tim se zahvaljuje!"
Strings.TEXT_FEEDBACK = "Vaše sugestije će doprineti da budete zadovoljniji našom uslugom!"
Strings.FEEDBACK_PLACEHOLDER = "Vaš utisak, komentar, sugestija..."
Strings.SEND = "Pošalji"
Strings.HISTORY_IS_EMPTY = "Istorija poručivanja je prazna"
Strings.ORDERS_IS_EMPTY = "Nema porudžbina"
Strings.DAY_MENU_IS_EMPTY = "Meni je prazan"

Strings.ARE_YOU_SURE_DELETE_ORDER = "Da li ste sigurni da želite da otkažete i obrišete porudžbinu?"
Strings.ORDER_SUCCESSFUL = "Uspešno ste napravili porudžbinu!"
Strings.ORDER_EDITED = "Uspešno ste izmenili porudžbinu!"

Strings.TEXT_SERPICA_HOME = "Šerpica Vam dostavlja ručak na kućnu adresu!"
Strings.TEXT_SERPICA_HOME_DELIVERY_INFO = "<div class=\"title\">Kućna dostava</div>Da bi ste koristili mogućnost kućne dostave potrebno je da unesete adresu za dostavu i izaberete \"želim dostavu na kućnu adresu\". U bilo kojem trenutku možete da se vratite na dostavu na adresu firme biranjem \"želim dostavu na adresu firme\". Način poručivanja je isti kao i do sada, sa razlikom što se ručak plaća odmah prilikom isporuke."
Strings.TEXT_SERPICA_HOME_DELIVERY_PRICE = "<div class=\"title\">Cena dostave</div>- <b>{deliveryPrice}din</b> za iznose do {freeDeliveryPriceLimit}din<br/>- Za iznose preko {freeDeliveryPriceLimit}din dostava je <b>besplatna</b><br/><br/>Navedene cene važe za dostavu na teritoriji Novog Sada."
Strings.TEXT_SERPICA_HOME_DELIVERY_POLICY = "<div class=\"title\">Obračun dostave</div>Cena dostave se obračunava pojedinačno za svaki termin na osnovu ukupnog iznosa poručenih paketa u tom terminu."
Strings.TEXT_DEACTIVATE_ACCOUNT = "Deaktivacijom naloga trajno brišete Vaš nalog. Porudžbine koje su Vam isporučene a nisu naplaćene će biti naplaćene prilikom prvog narednog obračuna."
Strings.TEXT_CHANGE_COMPANY = "Promenom ustanove trajno brišete Vaš nalog na prethodnoj ustanovi a automatski Vam se pravi novi nalog na novoj ustanovi. Porudžbine koje su Vam isporučene a nisu naplaćene će biti naplaćene prilikom prvog narednog obračuna."

Strings.ACCOUNT_PAGE = "Podešavanje naloga"
Strings.SERPICA_HOME_SETTINGS = "Kućna dostava"
Strings.CHANGE_PASSWORD = "Promena lozinke"
Strings.CHANGE_EMAIL = "Promena E-mail adrese"
Strings.CHANGE_SECTOR = "Promena odeljenja"
Strings.CHANGE_COMPANY = "Promena ustanove"
Strings.DEACTIVATE_ACCOUNT = "Deaktivacija naloga"
Strings.LOGOUT = "Odjava"

Strings.PASSWORDS_DO_NOT_MATCH = "Lozinka i potvrda lozinke se ne podudaraju!"
Strings.PASSWORD_CHANGED = "Uspešno ste promenili lozinku!"
Strings.EMAIL_CHANGED = "Uspešno ste promenili E-mail adresu!"
Strings.SECTOR_CHANGED = "Uspešno ste promenili odeljenje!"
Strings.COMPANY_CHANGED = "Uspešno ste promenili ustanovu!"
Strings.DELIVERY_ADDRESS_CHANGED = "Uspešno ste promenili adresu dostave!"

Strings.NOTIFICATION = "Obaveštenje"
Strings.DEMO_ACCOUNT_NOTIFICATION = "Dobrodošli na test nalog čija je svrha da Vas uputi u način funkcionisanja Šerpice!<br/><br/><b>Slobodno se krećite kroz aplikaciju, ne postoji mogućnost da ostvarite troškove na test nalogu.</b><br/><br/>Ukoliko želite da postanete korisnik Šerpice, kontaktirajte nas na email <b>kontakt@serpica021.rs</b> ili broj telefona <b>064 144 69 01</b>."

export default Strings
