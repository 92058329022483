import React, { useState } from 'react'
import Strings from '../config/Strings'
import { formatCurrency } from '../config/Util'
import Dialog from './Dialog'
import { OrderService } from '../services/OrderService'
import { useHistory } from 'react-router-dom'
import { HomeOrderService } from '../services/HomeOrderService'

function DayItem(props) {

    const [showDialog, setShowDialog] = useState(false)
    const [dialogData, setDialogData] = useState({})

    const history = useHistory()
    
    function openDeleteOrderDialog() {
        if (props.isDisabled)
            return

        setDialogData({
            title: Strings.ARE_YOU_SURE_DELETE_ORDER
        })

        setShowDialog(true)
    }

    function deleteOrder() {
        if (props.isDisabled)
            return

        OrderService.deleteOrder(props.orderId)
            .then(response => {
                if (response === "uspesnoobrisano") {
                    props.onDelete()
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    function deleteHomeOrder() {
        if (props.isDisabled)
            return
        
        HomeOrderService.deleteOrder(props.orderId)
            .then(response => {
                if (response === "uspesnoobrisano") {
                    props.onDelete()
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    function editOrder() {
        if (props.isDisabled)
            return

        OrderService.getOrderInfo(props.orderId)
            .then(response => {
                history.push("/day/" + props.dayName + "/" + props.day + "/" + props.month + "/" + props.year + "/" + props.homeDelivery + "?orderId=" + props.orderId + "&cart=" + btoa(JSON.stringify(response)))
            })
            .catch(error => {
                console.log(error)
            })
    }

    function editHomeOrder() {
        if (props.isDisabled)
            return
        
        HomeOrderService.getOrderInfo(props.orderId)
            .then(response => {
                history.push("/day/" + props.dayName + "/" + props.day + "/" + props.month + "/" + props.year + "/" + props.homeDelivery + "?orderId=" + props.orderId + "&cart=" + btoa(JSON.stringify(response)))
            })
            .catch(error => {
                console.log(error)
            })
    }

    function setClassList() {
        let classes = ["day-item"]

        if (props.isDisabled) {
            classes.push("disabled")
        }

        if (!props.disableAnimation) {
            classes.push("show-animation")
        }

        return classes.join(" ")
    }

	return (
    	<div className={setClassList()}>
            <div className="text">
                {props.text}
            </div>

            <div className="right">
                <div className="actions">
                    <svg onClick={props.homeDelivery ? editHomeOrder : editOrder} width="20" height="18" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.6588 0C15.4088 0 15.1488 0.1 14.9588 0.29L13.1288 2.12L16.8788 5.87L18.7088 4.04C19.0988 3.65 19.0988 3.02 18.7088 2.63L16.3688 0.29C16.1688 0.09 15.9188 0 15.6588 0ZM12.0588 6.02L12.9788 6.94L3.91878 16H2.99878V15.08L12.0588 6.02ZM0.998779 14.25L12.0588 3.19L15.8088 6.94L4.74878 18H0.998779V14.25Z" />
                    </svg>
                    <svg onClick={openDeleteOrderDialog} width="14" height="18" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.5 0L10.5 1H14V3H0V1H3.5L4.5 0H9.5ZM7 9.59L9.12 7.47L10.53 8.88L8.41 11L10.53 13.12L9.12 14.53L7 12.41L4.88 14.53L3.47 13.12L5.59 11L3.46 8.88L4.87 7.47L7 9.59ZM1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM11 6H3V16H11V6Z" />
                    </svg>
                </div>
                <div className="total">
                    {Strings.TOTAL + formatCurrency(props.total)}
                </div>
            </div>

            {
                showDialog ? (
                    <Dialog 
                        data={dialogData}
                        primaryText={Strings.DELETE}
                        primaryAction={() => props.homeDelivery ? deleteHomeOrder() : deleteOrder()}
                        secondaryText={Strings.CANCEL}
                        secondaryAction={ () => setShowDialog(false) }
                    />
                ) : null
            }

        </div>
	)
}

export default DayItem