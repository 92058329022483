import React, { useEffect, useState } from 'react'
import Menu from '../components/Menu'
import Header from '../components/Header'
import { withPaddingPage, getDateAsYearMonthDay } from '../config/Util'
import DayChooser from '../components/DayChooser'
import Strings from '../config/Strings'
import { AccountService } from '../services/AccountService'
import Dialog from '../components/Dialog'

function Home(props) {

    withPaddingPage()

    const [globalNotification, setGlobalNotification] = useState("")
    const [companyNotification, setCompanyNotification] = useState("")
    const [sectorNotification, setSectorNotification] = useState("")
    const [demoAccountNotification, setDemoAccountNotification] = useState("")

    useEffect(() => {
        AccountService.getNotifications()
            .then(response => {
                setGlobalNotification(response[0])
                setCompanyNotification(response[1])
                setSectorNotification(response[2])
            })
            .catch(error => {
                console.log(error)
            })

        if (props.sessionData.accountType === "demo") {
            setDemoAccountNotification(Strings.DEMO_ACCOUNT_NOTIFICATION)
        }

    }, [])

    function notificationSeen(whichNotification) {
        AccountService.setNotificationSeen(whichNotification)
            .catch(error => {
                console.log(error)
                return
            })

        switch(whichNotification) {
            case "global":
                setGlobalNotification("")
                break
            case "company":
                setCompanyNotification("")
                break
            case "sector":
                setSectorNotification("")
                break
        }
    }

    function initializeDates() {
        let dayNames = Strings.DAY_NAMES_ARRAY
        let date = new Date()
        let increaseDay = (date.getHours() >= props.sessionData.finalTime) ? 1 : 0
    
        let output = []
    
        for (let i = 0; i < 5; i ++) {
            date = new Date(Date.now() + (i + increaseDay) * 24 * 60 * 60 * 1000)

            let [year, month, day] = getDateAsYearMonthDay(date)

            output.push(
                <DayChooser
                    key={i}
                    homeDelivery={props.sessionData.homeDelivery}
                    text={(i === 0 && increaseDay === 0) ? Strings.TODAY : dayNames[date.getDay()]}
                    day={day}
                    month={month}
                    year={year}
                    disabled={isDateDisabled(date)}
                />
            )
    
        }
    
        return output
    }
    
    function isDateDisabled(date) {
        return (date.getDay() === 0 || date.getDay() === 6) ? true : false
    }

    function setLogo() {
        if (props.sessionData.homeDelivery === undefined)
            return <img src="assets/img/logo.png" className="logo show-animation" alt="Logo" />
        
        if (props.sessionData.homeDelivery === true)
            return <img src="assets/img/logo-home.png" className="logo show-animation" alt="Logo" />

        if (props.sessionData.homeDelivery === false)
            return <img src="assets/img/logo-office.png" className="logo show-animation" alt="Logo" />
    }

	return (
        <div>
            <Header text={Strings.PAGE_HOME + props.sessionData.name + "!"} />

            {setLogo()}
            
            {initializeDates()}

            <Menu active="home" />

            {
				sectorNotification !== "" ? <Dialog primaryText={Strings.OK} primaryAction={ () => notificationSeen("sector") } data={ { title: Strings.NOTIFICATION, message: sectorNotification } } /> : null
			}

            {
				companyNotification !== "" ? <Dialog primaryText={Strings.OK} primaryAction={ () => notificationSeen("company") } data={ { title: Strings.NOTIFICATION, message: companyNotification } } /> : null
			}

            {
				globalNotification !== "" ? <Dialog primaryText={Strings.OK} primaryAction={ () => notificationSeen("global") } data={ { title: Strings.NOTIFICATION, message: globalNotification } } /> : null
			}

            {
				(demoAccountNotification !== "" && !props.demoAccountNotificationRead) ? <Dialog primaryText={Strings.OK} primaryAction={ () => props.setDemoAccountNotificationRead(true) } data={ { title: Strings.NOTIFICATION, message: demoAccountNotification } } /> : null
			}
        </div>
	)
}

export default Home
