import React, { useEffect, useState } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import { withPaddingPage } from '../../config/Util'
import Strings from '../../config/Strings'
import AccountInfo from '../../components/AccountInfo'
import { useHistory } from 'react-router-dom'
import { AccountService } from '../../services/AccountService'
import Dialog from '../../components/Dialog'
import ReactRoutes from '../../config/ReactRoutes'

function DeactivateAccount(props) {

    withPaddingPage()

    const [yourPassword, setYourPassword] = useState("")
    const [showErrorDialog, setShowErrorDialog] = useState(false)
    const [errorDialogData, setErrorDialogData] = useState({})

    const history = useHistory()

    useEffect(() => {

    }, [])

    function deactivateAccount(e) {
        if (validateInput()) {
            let targetClassList = e.target.classList;
            targetClassList.add("spinner")

            AccountService.deactivateAccount(yourPassword)
                .then(response => {
                    if (response === "nalogdeaktiviran") {
                        history.push(ReactRoutes.INDEX)
                    } else {
                        setErrorDialogData({
                            title: response
                        })
                        setShowErrorDialog(true)
                    }
                    targetClassList.remove("spinner")
                })
                .catch(error => {
                    console.log(error)
                    targetClassList.remove("spinner")
                })
        }
    }

    function validateInput() {
        let valid = true

        if (!yourPassword) {
            valid = false
            setErrorDialogData({
                title: Strings.ERROR_MUST_FILL_ALL_FIELDS
            })
        }

        setShowErrorDialog(!valid)
        return valid
    }

	return (
        <div>
            <Header back={ () => history.goBack() } text={Strings.DEACTIVATE_ACCOUNT} />
            <AccountInfo
                name={props.sessionData.name}
                lastName={props.sessionData.lastName}
                telephone={props.sessionData.telephone}
                email={props.sessionData.email}
                companyName={props.sessionData.companyName}   
            />

            <p className="text show-animation">{Strings.TEXT_DEACTIVATE_ACCOUNT}</p>

			<input onChange={ (e) => setYourPassword(e.target.value) } className="input mt2rem show-animation" placeholder="Vaša lozinka" type="password" />

            <button onClick={deactivateAccount} className="btn btn-green mt2rem show-animation">{Strings.DEACTIVATE}</button>

            <Menu active="account" />

            {
				showErrorDialog ? <Dialog primaryText={Strings.OK} primaryAction={ () => setShowErrorDialog(false) } data={errorDialogData} /> : null
			}
        </div>
	)
}

export default DeactivateAccount
