import React, { useState } from 'react'
import MealOption from './MealOption'
import ApiRoutes from '../config/ApiRoutes'
import Strings from '../config/Strings'
import {getMealOptionWeight} from "../config/Util";

function Meal(props) {

    const [selectedOption, setSelectedOption] = useState(props.selectedOptionInitial)

    function setSpicy() {
        if (props.spicy) {
            if (props.posno) {
                return <div className="flag first flag-red">Ljuto</div>
            } else {
                return <div className="flag flag-red">Ljuto</div>
            }
        }
    }

    function setPosno() {
        if (props.posno) {
            if (props.spicy) {
                return <div className="flag second flag-green">Posno</div>
            } else {
                return <div className="flag flag-green">Posno</div>
            }
        }
    }

    function setVege() {
        if (props.vege) {
            if (props.hrono || props.fit) {
                return <div className="flag-right first flag-orange">Vege</div>
            } else {
                return <div className="flag-right flag-orange">Vege</div>
            }
        }
    }

    function setHrono() {
        if (props.hrono) {
            if (props.vege) {
                return <div className="flag-right second flag-yellow">Hrono</div>
            } else {
                if (props.fit) {
                    return <div className="flag-right first flag-yellow">Hrono</div>
                } else {
                    return <div className="flag-right flag-yellow">Hrono</div>
                }
            }
        }
    }

    function setFit() {
        if (props.fit) {
            if (props.vege && props.hrono) {
                return <div className="flag-right third flag-light-green">Fit</div>
            } else if (props.vege || props.hrono) {
                return <div className="flag-right second flag-light-green">Fit</div>
            } else {
                return <div className="flag-right flag-light-green">Fit</div>
            }
        }
    }

    function setOptions() {
        if (props.onStock === 0) {
            return (
                <div className="options one-option sold">
                    <MealOption name={Strings.SOLD} />
                </div>
            )
        }

        if (props.default !== 0) {
            const weightLabel = getMealOptionWeight(props.category, 3)

            return (
                <div className={weightLabel !== undefined ? "options with-weights one-option" : "options one-option"}>
                    <MealOption
                        selectedOption={selectedOption}
                        selectOption={handleOptionClick}
                        id="3"
                        name="Porcija"
                        price={props.default}
                        percentageToShow={props.percentageToShow}
                        weightLabel={weightLabel}
                    />
                </div>
            )
        }

        if (props.category === 6) {
            return (
                <div className="options two-options">
                    <MealOption selectedOption={selectedOption} selectOption={handleOptionClick} id="1" name="Jedan paket" price={props.small} />
                    <MealOption selectedOption={selectedOption} selectOption={handleOptionClick} id="2" name="Dva paketa" price={props.big} />
                </div>
            )
        }

        let options = []
        let hasWeightLabels = false

        if (parseInt(props.small) > 0) {
            const weightLabel = getMealOptionWeight(props.category, 1)
            if (weightLabel !== undefined) {
                hasWeightLabels = true
            }

            options.push(<MealOption key="small" selectedOption={selectedOption} selectOption={handleOptionClick} id="1" name="S" price={props.small} weightLabel={weightLabel} />)
        }

        if (parseInt(props.big) > 0) {
            const weightLabel = getMealOptionWeight(props.category, 2)
            if (weightLabel !== undefined) {
                hasWeightLabels = true
            }

            options.push(<MealOption key="big" selectedOption={selectedOption} selectOption={handleOptionClick} id="2" name="L" price={props.big} weightLabel={weightLabel} />)
        }

        if (parseInt(props.xl) > 0) {
            const weightLabel = getMealOptionWeight(props.category, 4)
            if (weightLabel !== undefined) {
                hasWeightLabels = true
            }

            options.push(<MealOption key="xl" selectedOption={selectedOption} selectOption={handleOptionClick} id="4" name="XL" price={props.xl} weightLabel={weightLabel} />)
        }

        const classList = ["options"]

        if (hasWeightLabels) {
            classList.push("with-weights")
        }

        if (options.length === 1) {
            classList.push("one-option")
        }

        if (options.length === 2) {
            classList.push("two-options")
        }

        return (
            <div className={classList.join(" ")}>
                {options}
            </div>
        )
    }

    function handleOptionClick(option, price) {
        setSelectedOption(option)
        props.selectedOptionChange([props.id, option, props.category, price])
    }

	return (
        <div className="meal show-animation">

            {setSpicy()}
            {setPosno()}
            {setVege()}
            {setHrono()}
            {setFit()}

            <div className="image">
                <img src={ApiRoutes.MEAL_IMAGES_URL + props.image} alt={props.name} />
            </div>
            <div className="description">{props.description}</div>
            <div className="name">{props.name}</div>

            {setOptions()}

        </div>
	)
}

export default Meal
