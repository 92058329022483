import React from 'react'
import { formatCurrency } from '../config/Util'

function HistoryItemsWithDelivery(props) {

    function setClassList() {
        let classes = ["history-items-with-delivery"]

        if (!props.disableAnimation) {
            classes.push("show-animation")
        }

        if (!props.isHomeDelivery) {
            classes.push('disabled')
        }

        return classes.join(" ")
    }

    return (
        <div className={setClassList()}>
            <div className="info">
                <div className="item">
                    {props.deliveryTime}
                </div>

                <div className="item">
                    Ukupno sa dostavom: {formatCurrency(props.total)}
                </div>
            </div>
            {props.children}
        </div>
    )
}

export default HistoryItemsWithDelivery
