import ApiRoutes from '../config/ApiRoutes'
import { checkAuthentication, setFinalDeliveryTime } from '../config/Util'

export const AccountService = {
    cookieLogIn: function() {
        return new Promise((resolve, reject) => {
            fetch(ApiRoutes.COOKIE_LOG_IN, {
                method: "GET",
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    getSessionData: function(withCheckAuthentication = true) {
        return new Promise((resolve, reject) => {
            fetch(ApiRoutes.GET_SESSION_DATA, {
                method: "GET",
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.json()
                }
            })
            .then(response => {
                if (withCheckAuthentication) {
                    checkAuthentication(response)
                }
                return response
            })
            .then(data => setFinalDeliveryTime(data))
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    getNotifications: function() {
        return new Promise((resolve, reject) => {
            fetch(ApiRoutes.GET_NOTIFICATIONS, {
                method: "GET",
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.json()
                }
            })
            .then(checkAuthentication)
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    setNotificationSeen: function(whichNotification) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            switch(whichNotification) {
                case "global":
                    whichNotification = "opste"
                    break
                case "company":
                    whichNotification = "poUstanovi"
                    break
                case "sector":
                    whichNotification = "poOdeljenju"
                    break
            }

            formData.append("koje", whichNotification)

            fetch(ApiRoutes.SET_NOTIFICATION_SEEN, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(checkAuthentication)
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    leaveFeedback: function(message) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("utisak", message)

            fetch(ApiRoutes.LEAVE_FEEDBACK, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(checkAuthentication)
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    logOut: function() {
        return new Promise((resolve, reject) => {

            fetch(ApiRoutes.LOGOUT, {
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    changeDeliveryAddress: function(password, streetAddress, buildingLevel, doorNumber, homeDelivery) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("vasasifra", password)
            formData.append("ulicaibroj", streetAddress)
            formData.append("sprat", buildingLevel)
            formData.append("stan", doorNumber)
            formData.append("kucnaDostava", homeDelivery)

            fetch(ApiRoutes.CHANGE_DELIVERY_ADDRESS, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(checkAuthentication)
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    changePassword: function(oldPassword, newPassword) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("starasifra", oldPassword)
            formData.append("novasifra", newPassword)

            fetch(ApiRoutes.CHANGE_PASSWORD, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(checkAuthentication)
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    changeEmail: function(password, newEmail) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("vasasifra", password)
            formData.append("noviemail", newEmail)

            fetch(ApiRoutes.CHANGE_EMAIL, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(checkAuthentication)
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    deactivateAccount: function(password) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("vasasifra", password)

            fetch(ApiRoutes.DEACTIVATE_ACCOUNT, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(checkAuthentication)
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    changeSector: function(password, sectorId) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("vasasifra", password)
            formData.append("odeljenje", sectorId)

            fetch(ApiRoutes.CHANGE_SECTOR, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(checkAuthentication)
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    changeCompany: function(password, companyId, sectorId) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("vasasifra", password)
            formData.append("ustanova", companyId)
            formData.append("odeljenje", sectorId)

            fetch(ApiRoutes.CHANGE_COMPANY, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(checkAuthentication)
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    }
}
