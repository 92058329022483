import React from 'react'

function Empty(props) {

	return (
        <div className="empty show-animation">
            <div className="icon">
                <svg width="221" height="200" viewBox="0 0 221 200" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M215.415 139.532L145.362 19.7762C138.329 7.58398 125.221 0.0166217 111.045 2.73545e-05C96.8669 -0.0165687 83.754 7.51956 76.6336 19.7767L6.55425 139.576C-0.712489 151.795 -0.876884 167.105 6.16176 179.592C13.2063 192.089 26.3966 199.866 40.744 199.983L181.123 199.983C195.619 199.842 208.784 192.089 215.828 179.603C222.863 167.132 222.706 151.856 215.415 139.532ZM23.7808 149.737L93.9121 29.8491C97.4637 23.7354 103.978 19.9918 111.021 20C118.064 20.0083 124.576 23.7673 128.068 29.8222L198.177 149.673C201.852 155.885 201.931 163.533 198.408 169.777C194.881 176.03 188.287 179.913 181.025 179.984L40.8253 179.983C33.7192 179.925 27.1122 176.029 23.5843 169.771C20.0603 163.519 20.1426 155.855 23.7808 149.737ZM111.003 159.983C116.528 159.983 121.007 155.506 121.007 149.983C121.007 144.461 116.528 139.983 111.003 139.983C105.479 139.983 101 144.461 101 149.983C101 155.506 105.479 159.983 111.003 159.983ZM121.036 59.9834H101.03V129.983H121.036V59.9834Z" />
                </svg>
            </div>
            <div className="text">
                {props.text}
            </div>
        </div>
	)
}

export default Empty
