import React, { useState, useEffect } from 'react'
import Menu from '../components/Menu'
import Header from '../components/Header'
import { withPaddingPage } from '../config/Util'
import Empty from '../components/Empty'
import Loader from '../components/Loader'
import Strings from '../config/Strings'
import { OrderService } from '../services/OrderService'
import HistoryWeekTotal from "../components/HistoryWeekTotal";
import HistoryItem from "../components/HstoryItem";
import HistoryItemsWithDelivery from "../components/HistoryItemsWithDelivery";
import HistoryDay from "../components/HistoryDay";

function History(props) {

    withPaddingPage()

    const [isLoaded, setIsLoaded] = useState(false)
    const [ordersHistory, setOrdersHistory] = useState([])

    useEffect(() => {
        OrderService.getOrdersHistory()
        .then(ordersHistory => {
            setOrdersHistory(ordersHistory.orders)
            setIsLoaded(true)
        })
        .catch(error => {
            console.log(error)
        })
    }, [])

    function printOrdersHistory() {
        let output = []
        let key = 0;

        if (ordersHistory.length === 0) {
            return historyIsEmpty()
        }

        for (let week of ordersHistory) {
            for (let day of week.items) {
                output.push(
                    <HistoryDay key={key++} date={day.date} total={day.total} companyDiscount={day.companyDiscount}>
                        {day.items.map(order => {
                            return !order.isHome
                                ? <HistoryItem key={key++} disableAnimation={true} isDisabled={props.sessionData.homeDelivery} name={order.name} date={order.date} total={order.total} />
                                : <HistoryItemsWithDelivery key={key++} disableAnimation={true} isHomeDelivery={props.sessionData.homeDelivery} deliveryTime={order.period} total={order.total}>
                                    {order.items.map(item => <HistoryItem key={key++} noShadow={true} disableAnimation={true} isDisabled={!props.sessionData.homeDelivery} name={item.name} date={item.date} total={item.total} />)}
                                  </HistoryItemsWithDelivery>
                        })}
                    </HistoryDay>
                )
            }

            output.push(<HistoryWeekTotal key={key++} firstDate={week.firstDate} lastDate={week.lastDate} total={week.total} />)
        }

        return output
    }

    function historyIsEmpty() {
        return <Empty text={Strings.HISTORY_IS_EMPTY} />
    }

    function historyIsLoading() {
        return <Loader />
    }

	return (
        <div>
            <Header text={Strings.PAGE_HISTORY} />

            {
                isLoaded ? printOrdersHistory() : historyIsLoading()
            }

            <Menu active="history" />
        </div>
	)
}

export default History
