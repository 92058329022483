import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import Login from './pages/Login'
import Register from './pages/Register'
import Home from './pages/Home';
import MyOrders from './pages/MyOrders';
import History from './pages/History';
import Feedback from './pages/Feedback';
import Day from './pages/Day';
import ReactRoutes from './config/ReactRoutes';
import { AccountService } from './services/AccountService';
import Account from './pages/Account';
import HomeSettings from './pages/account/HomeSettings';
import ChangePassword from './pages/account/ChangePassword';
import ChangeEmail from './pages/account/ChangeEmail';
import DeactivateAccount from './pages/account/DeactivateAccount';
import ChangeSector from './pages/account/ChangeSector';
import ChangeComapny from './pages/account/ChangeCompany';
import ForgottenPassword from './pages/ForgottenPassword';

function App() {

	const [sessionData, setSessionData] = useState([])
	const [variableToWatch, setVariableToWatch] = useState(false)
	const [demoAccountNotificationRead, setDemoAccountNotificationRead] = useState(false)

	const history = useHistory()

	history.listen(() => {
		window.scrollTo(0, 0)
	})
	
	useEffect(() => {
		AccountService.getSessionData(false)
			.then(sessionData => {
				setSessionData(sessionData)
			})
			.catch(error => {
				console.log(error)
			})
	}, [variableToWatch])

	function triggerRefreshSessionData() {
		setVariableToWatch(!variableToWatch)
	}

	return (
		<Switch>
			<Route path={ReactRoutes.LOGIN}>
				<Login setSessionData={setSessionData} setDemoAccountNotificationRead={setDemoAccountNotificationRead} />
			</Route>

			<Route path={ReactRoutes.REGISTER}>
				<Register setSessionData={setSessionData} setDemoAccountNotificationRead={setDemoAccountNotificationRead} />
			</Route>

			<Route path={ReactRoutes.FORGOTTEN_PASSWORD}>
				<ForgottenPassword />
			</Route>

			<Route path={ReactRoutes.HOME}>
				<Home sessionData={sessionData} demoAccountNotificationRead={demoAccountNotificationRead} setDemoAccountNotificationRead={setDemoAccountNotificationRead} />
			</Route>

			<Route path={ReactRoutes.DAY} component={Day} />

			<Route path={ReactRoutes.MY_ORDERS}>
				<MyOrders sessionData={sessionData} />
			</Route>

			<Route path={ReactRoutes.ORDERS_HISTORY}>
				<History sessionData={sessionData} />
			</Route>

			<Route path={ReactRoutes.FEEDBACK}>
				<Feedback />
			</Route>

			<Route path={ReactRoutes.SERPICA_HOME_SETTINGS}>
				<HomeSettings triggerRefreshSessionData={triggerRefreshSessionData} sessionData={sessionData} />
			</Route>

			<Route path={ReactRoutes.CHANGE_PASSWORD}>
				<ChangePassword sessionData={sessionData} />
			</Route>

			<Route path={ReactRoutes.CHANGE_EMAIL}>
				<ChangeEmail triggerRefreshSessionData={triggerRefreshSessionData} sessionData={sessionData} />
			</Route>

			<Route path={ReactRoutes.DEACTIVATE_ACCOUNT}>
				<DeactivateAccount sessionData={sessionData} />
			</Route>

			<Route path={ReactRoutes.CHANGE_SECTOR}>
				<ChangeSector triggerRefreshSessionData={triggerRefreshSessionData} sessionData={sessionData} />
			</Route>

			<Route path={ReactRoutes.CHANGE_COMPANY}>
				<ChangeComapny triggerRefreshSessionData={triggerRefreshSessionData} sessionData={sessionData} />
			</Route>

			<Route path={ReactRoutes.ACCOUNT}>
				<Account sessionData={sessionData} />
			</Route>

			<Route path={ReactRoutes.INDEX}>
				<Redirect to={ReactRoutes.LOGIN} />
			</Route>
		</Switch>
	);
}

export default App