import React, { useState, useEffect } from 'react'
import { noPaddingPage } from '../config/Util'
import { useHistory } from 'react-router-dom'
import Dialog from '../components/Dialog'
import { LogInRegisterService } from '../services/LogInRegisterService'
import Strings from '../config/Strings'
import { AccountService } from '../services/AccountService'
import ReactRoutes from '../config/ReactRoutes'
import Loader from '../components/Loader'

function Login(props) {

	noPaddingPage()

	const history = useHistory()

	const [isLoaded, setIsLoaded] = useState(false)
	const [telephone, setTelephone] = useState("")
	const [password, setPassword] = useState("")
	const [rememberMe, setRememberMe] = useState(false)

	const [showDialog, setShowDialog] = useState(false)
	const [dialogData, setDialogData] = useState({})

	useEffect(() => {
		props.setDemoAccountNotificationRead(false)
		AccountService.cookieLogIn()
			.then(response => {
				if (response === "ulogovan") {
					AccountService.getSessionData()
						.then(sessionData => {
							props.setSessionData(sessionData)
							history.push(ReactRoutes.HOME)
						})
						.catch(error => {
							console.log(error)
							setIsLoaded(true)
						})
				} else {
					setIsLoaded(true)
				}
			})
			.catch(error => {
				console.log(error)
				setIsLoaded(true)
			})
	}, [])

	function logIntoTestAccount(e) {
		let targetClassList = e.target.classList;
		targetClassList.add("spinner")

		LogInRegisterService.logIn("060123456", "123456", false)
		.then(response => {
			if (response === "ulogovan") {
				AccountService.getSessionData()
					.then(sessionData => {
						props.setSessionData(sessionData)
						history.push(ReactRoutes.HOME)
					})
					.catch(error => {
						console.log(error)
						targetClassList.remove("spinner")
					})
			}
		})
		.catch(error => {
			console.log(error)
			targetClassList.remove("spinner")
		})
	}

	function validateInput() {
        let valid = true

        if (!telephone || !password) {
            valid = false
            setDialogData({
                title: Strings.ERROR_MUST_FILL_ALL_FIELDS_LOGIN
            })
        }

        setShowDialog(!valid)
        return valid
    }

	async function logIn(e) {
		if (validateInput()) {
			let targetClassList = e.target.classList;
			targetClassList.add("spinner")

			LogInRegisterService.logIn(telephone, password, rememberMe)
			.then(response => {
				if (response === "ulogovan") {
					AccountService.getSessionData()
						.then(sessionData => {
							props.setSessionData(sessionData)
							history.push(ReactRoutes.HOME)
						})
						.catch(error => {
							console.log(error)
							targetClassList.remove("spinner")
						})
				} else {
					targetClassList.remove("spinner")
					setDialogData({
						title: response
					})
					setShowDialog(true)
				}
			})
			.catch(error => {
				console.log(error)
				targetClassList.remove("spinner")
			})
		}
	}

	if (isLoaded) {
		return (
			<div className="show-animation" style={{ overflow: "auto" }}>
				<img src="assets/img/logo.png" className="logo" alt="logo" />
				<button onClick={logIntoTestAccount} className="btn mb3rem">Istražite šerpicu</button>

				<input onChange={ (e) => setTelephone(e.target.value) } className="input" placeholder="Vaš broj telefona" type="tel" />
				<input onChange={ (e) => setPassword(e.target.value) } className="input" placeholder="Vaša lozinka" type="password" />

				<div className="check-wrapper">
					<input onChange={ (e) => setRememberMe(e.target.value) } className="check" value="da" type="checkbox" id="remembermecheckbox" />
					<label htmlFor="remembermecheckbox">zapamti me</label>
				</div>

				<button onClick={logIn} className="btn btn-green">Prijavi se</button>

				<a className="link" onClick={ (e) => { e.preventDefault(); history.push(ReactRoutes.FORGOTTEN_PASSWORD) }} href="/">Zaboravljena lozinka?</a>

				<button onClick={ () => history.push("/register") } className="btn mt3rem">Otvori nalog</button>

				{
					showDialog ? <Dialog primaryText={Strings.OK} primaryAction={ () => setShowDialog(false) } data={dialogData} /> : null
				}

			</div>
		)
	} else {
		return (
			<Loader fullScreen />
		)
	}
}

export default Login