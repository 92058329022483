const ApiRoutes = []

ApiRoutes.BASE_URL = "https://app.serpica021.rs"
// ApiRoutes.BASE_URL = "http://192.168.0.11:8000"
ApiRoutes.SERPICA021RS_URL = "https://app.serpica021.rs"

ApiRoutes.SERPICA_HOME = "/home"

ApiRoutes.MEAL_IMAGES_URL = ApiRoutes.SERPICA021RS_URL + "/img/slikejela/"

ApiRoutes.LOGIN = ApiRoutes.BASE_URL + "/funkcije/logovanje.php"
ApiRoutes.GET_ALL_COMPANIES = ApiRoutes.BASE_URL + "/funkcije/getUstanove.php"
ApiRoutes.GET_COMPANY_SECTORS = ApiRoutes.BASE_URL + "/funkcije/getOdeljenja.php"
ApiRoutes.REGISTER = ApiRoutes.BASE_URL + "/funkcije/registrovanje.php"
ApiRoutes.LOGOUT = ApiRoutes.BASE_URL + "/funkcije/api/logoutApi.php"
ApiRoutes.TERMS_AND_CONDITIONS = ApiRoutes.BASE_URL + "/funkcije/getUsloviKoriscenja.php"

ApiRoutes.RESET_PASSWORD_VIA_TELEPHONE = ApiRoutes.BASE_URL + "/funkcije/zaboravljenaSifraTelefon.php"
ApiRoutes.RESET_PASSWORD_VIA_EMAIL = ApiRoutes.BASE_URL + "/funkcije/zaboravljenaSifraEmail.php"

ApiRoutes.CHANGE_DELIVERY_ADDRESS = ApiRoutes.BASE_URL + "/funkcije" + ApiRoutes.SERPICA_HOME + "/sacuvajNovuAdresuDostaveIMestoDostave.php"
ApiRoutes.HOME_MAKE_NEW_ORDER = ApiRoutes.BASE_URL + "/funkcije" + ApiRoutes.SERPICA_HOME + "/upisiNoviPaket.php"
ApiRoutes.HOME_GET_MY_ORDERS = ApiRoutes.BASE_URL + "/funkcije" + ApiRoutes.SERPICA_HOME + "/getPaketi.php"
ApiRoutes.HOME_DELETE_ORDER = ApiRoutes.BASE_URL + "/funkcije" + ApiRoutes.SERPICA_HOME + "/obrisiPaket.php"
ApiRoutes.HOME_GET_ORDER_INFO = ApiRoutes.BASE_URL + "/funkcije" + ApiRoutes.SERPICA_HOME + "/getPaketInfo.php"
ApiRoutes.HOME_SAVE_EDITED_ORDER = ApiRoutes.BASE_URL + "/funkcije" + ApiRoutes.SERPICA_HOME + "/sacuvajIzmenjenPaket.php"
ApiRoutes.HOME_GET_ORDERS_HISTORY = ApiRoutes.BASE_URL + "/funkcije" + ApiRoutes.SERPICA_HOME + "/getIstorijaPorucivanja.php"

ApiRoutes.CHANGE_PASSWORD = ApiRoutes.BASE_URL + "/funkcije/sacuvajNovuSifru.php"
ApiRoutes.CHANGE_EMAIL = ApiRoutes.BASE_URL + "/funkcije/sacuvajNoviEmail.php"
ApiRoutes.DEACTIVATE_ACCOUNT = ApiRoutes.BASE_URL + "/funkcije/deaktivirajNalog.php"
ApiRoutes.CHANGE_SECTOR = ApiRoutes.BASE_URL + "/funkcije/sacuvajNovoOdeljenje.php"
ApiRoutes.CHANGE_COMPANY = ApiRoutes.BASE_URL + "/funkcije/promeniUstanovu.php"

ApiRoutes.GET_NOTIFICATIONS = ApiRoutes.BASE_URL + "/funkcije/getOpsteObavestenje.php"
ApiRoutes.SET_NOTIFICATION_SEEN = ApiRoutes.BASE_URL + "/funkcije/upisiProcitaoObavestenje.php"

ApiRoutes.COOKIE_LOG_IN = ApiRoutes.BASE_URL + "/funkcije/api/cookieLoginApi.php"
ApiRoutes.GET_SESSION_DATA = ApiRoutes.BASE_URL + "/funkcije/api/sesijaApi.php"
ApiRoutes.GET_ORDERS_HISTORY = ApiRoutes.BASE_URL + "/funkcije/getIstorijaPorucivanja.php"
ApiRoutes.LEAVE_FEEDBACK = ApiRoutes.BASE_URL + "/funkcije/ostaviUtisak.php"
ApiRoutes.GET_DAY_MEALS = ApiRoutes.BASE_URL + "/funkcije/getJelaZaDan.php"
ApiRoutes.GET_STOCK_STATUS = ApiRoutes.BASE_URL + "/funkcije/getZalihe.php"
ApiRoutes.GET_MY_ORDERS = ApiRoutes.BASE_URL + "/funkcije/getPaketi.php"
ApiRoutes.DELETE_ORDER = ApiRoutes.BASE_URL + "/funkcije/obrisiPaket.php"
ApiRoutes.MAKE_NEW_ORDER = ApiRoutes.BASE_URL + "/funkcije/upisiNoviPaket.php"
ApiRoutes.GET_ORDER_INFO = ApiRoutes.BASE_URL + "/funkcije/getPaketInfo.php"
ApiRoutes.SAVE_EDITED_ORDER = ApiRoutes.BASE_URL + "/funkcije/sacuvajIzmenjenPaket.php"
ApiRoutes.SET_ORDER_IN_PROGRESS = ApiRoutes.BASE_URL + "/funkcije/upisiOtvoreniPaket.php"
ApiRoutes.REMOVE_ORDER_IN_PROGRESS = ApiRoutes.BASE_URL + "/funkcije/obrisiOtvoreniPaket.php"

export default ApiRoutes
