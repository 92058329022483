import React, { useEffect } from 'react'
import Strings from '../config/Strings'
import { formatCurrency } from '../config/Util'

function MakeOrder(props) {

    useEffect(() => {
        document.body.classList.add("make-order-show")
        return () => {
            document.body.classList.remove("make-order-show")
        }
    }, [])

    function handleClick() {
        props.onClick()
    }

    function setTotalDiv() {
        if (props.isHomeDelivery) {
            return <div className="total"> {Strings.TOTAL_WITHOUT_DELIVERY} <br /> {formatCurrency(props.total)} </div>
        } else {
            return <div className="total"> {Strings.TOTAL} {formatCurrency(props.total)} </div>
        }
    }

    function setOrderIcon() {
        if (props.isHomeDelivery)
            return

        return <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.9199 8.5H10.9199V5.5H7.91992V3.5H10.9199V0.5H12.9199V3.5H15.9199V5.5H12.9199V8.5ZM4.92992 19.5C4.92992 18.4 5.81992 17.5 6.91992 17.5C8.01992 17.5 8.91992 18.4 8.91992 19.5C8.91992 20.6 8.01992 21.5 6.91992 21.5C5.81992 21.5 4.92992 20.6 4.92992 19.5ZM16.9199 17.5C15.8199 17.5 14.9299 18.4 14.9299 19.5C14.9299 20.6 15.8199 21.5 16.9199 21.5C18.0199 21.5 18.9199 20.6 18.9199 19.5C18.9199 18.4 18.0199 17.5 16.9199 17.5ZM15.4699 12.5H8.01992L6.91992 14.5H18.9199V16.5H6.91992C5.39992 16.5 4.43992 14.87 5.16992 13.53L6.51992 11.09L2.91992 3.5H0.919922V1.5H4.18992L8.44992 10.5H15.4699L19.3399 3.5L21.0799 4.46L17.2199 11.47C16.8799 12.09 16.2199 12.5 15.4699 12.5Z" />
                </svg>
    }

    function setOrderText() {
        if (props.isHomeDelivery) {
            return Strings.CHOOSE_DELIVERY_TIME
        } else {
            if (props.isEdit) {
                return Strings.SAVE
            } else {
                return Strings.MAKE_ORDER
            }
        }
    }

	return (
        <div className="make-order">

            {setTotalDiv()}

            <div onClick={handleClick} className="confirm" style={{ textAlign: "center", fontSize: props.isHomeDelivery ? "0.8rem" : null }}>
                {setOrderIcon()}

                {setOrderText()}
            </div>
        </div>
	)
}

export default MakeOrder