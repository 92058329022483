import React, { useState } from 'react'
import Menu from '../components/Menu'
import Header from '../components/Header'
import { withPaddingPage } from '../config/Util'
import Dialog from '../components/Dialog'
import { AccountService } from '../services/AccountService'
import Strings from '../config/Strings'

function Feedback() {

    withPaddingPage()

    const [message, setMessage] = useState("")

    const [showDialog, setShowDialog] = useState(false)
    const [dialogData, setDialogData] = useState({})
    
    function validateInput() {
        let valid = true

        if (!message) {
            valid = false
            setDialogData({
                title:  Strings.ERROR_MUST_FILL_FEEDBACK_MESSAGE
            })
        }

        setShowDialog(!valid)
        return valid
    }

    function sendFeedback(e) {
        if (validateInput()) {
            let targetClassList = e.target.classList;
            targetClassList.add("spinner")
            
            AccountService.leaveFeedback(message)
			.then(response => {
                targetClassList.remove("spinner")

				if (response === "utisakostavljen") {
                    setMessage("")

					setDialogData({
						title: Strings.THANKS_FOR_FEEDBACK
					})
				} else {
					setDialogData({
                        title: Strings.ERROR,
						message: response
					})
                }

                setShowDialog(true)
			})
			.catch(error => {
				console.log(error)
				targetClassList.remove("spinner")
			})
        }
    }

	return (
        <div>
            <Header text={Strings.PAGE_FEEDBACK} />

            <img src="assets/img/logo.png" className="logo show-animation" alt="Logo" />

            <p className="text show-animation">{Strings.TEXT_FEEDBACK}</p>

            <textarea onChange={ (e) => setMessage(e.target.value) } value={message} className="textarea mt2rem show-animation" placeholder={Strings.FEEDBACK_PLACEHOLDER}></textarea>
            
            <button onClick={sendFeedback} className="btn btn-green mt2rem show-animation">{Strings.SEND}</button>

            <Menu active="feedback" />

            {
				showDialog ? <Dialog primaryText={Strings.OK} primaryAction={ () => setShowDialog(false) } data={dialogData} /> : null
			}
        </div>
	)
}

export default Feedback
