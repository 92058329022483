import ApiRoutes from "../config/ApiRoutes"
import { checkAuthentication, getDateAsYYYYMMDDString } from "../config/Util"

export const HomeOrderService = {
    getMyOrders: function(date) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("datum", getDateAsYYYYMMDDString(date))

            fetch(ApiRoutes.HOME_GET_MY_ORDERS, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.json()
                }
            })
            .then(checkAuthentication)
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    deleteOrder: function(orderId) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("idpaketa", orderId)

            fetch(ApiRoutes.HOME_DELETE_ORDER, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(checkAuthentication)
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    makeNewOrder: function(cart, date, deliveryTime) {
        return new Promise((resolve, reject) => {
            
            let formData = new FormData()

            formData.append("datum", getDateAsYYYYMMDDString(date))
            formData.append("korpa", JSON.stringify(cart))
            formData.append("termin", deliveryTime)

            fetch(ApiRoutes.HOME_MAKE_NEW_ORDER, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(checkAuthentication)
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    saveEditedOrder: function(orderId, cart, deliveryTime) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("idpak", orderId)
            formData.append("korpa", JSON.stringify(cart))
            formData.append("termin", deliveryTime)

            fetch(ApiRoutes.HOME_SAVE_EDITED_ORDER, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.text()
                }
            })
            .then(checkAuthentication)
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    },

    getOrderInfo: function(orderId) {
        return new Promise((resolve, reject) => {

            let formData = new FormData()

            formData.append("idpak", orderId)

            fetch(ApiRoutes.HOME_GET_ORDER_INFO, {
                method: "POST",
                body: formData,
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.json()
                }
            })
            .then(checkAuthentication)
            .then(data => {
                data.shift()
                resolve(data)
            })
            .catch(error => reject(error))

        })
    },

    getOrdersHistory: function() {
        return new Promise((resolve, reject) => {
            fetch(ApiRoutes.HOME_GET_ORDERS_HISTORY, {
                method: "GET",
                credentials: "include"
            })
            .then(response => {
                if (response.status !== 200) {
                    reject(response.statusText)
                } else {
                    return response.json()
                }
            })
            .then(checkAuthentication)
            .then(data => resolve(data))
            .catch(error => reject(error))

        })
    }

}
