import React from 'react'
import { useHistory } from 'react-router-dom'

function DayChooser(props) {

    const history = useHistory()

    function setClassList() {
        let classList = ["show-animation", "day-chooser"]

        if (props.disabled) {
            classList.push("disabled")
        }

        if (props.fromWhere === "my-orders") {
            classList.push("no-click")
        }

        return classList.join(" ")
    }

    function handleClick() {
        if (props.disabled || props.fromWhere === "my-orders")
            return

        history.push("/day/" + props.text + "/" + props.day + "/" + props.month + "/" + props.year + "/" + props.homeDelivery)
    }

    function handleClickAdd() {
        if (props.disabled || props.fromWhere !== "my-orders")
            return

        history.push("/day/" + props.text + "/" + props.day + "/" + props.month + "/" + props.year + "/" + props.homeDelivery)
    }

    function addMealsPacket() {
        return (
            <div onClick={handleClickAdd} className="add">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM10.8 6V10.8H6V13.2H10.8V18H13.2V13.2H18V10.8H13.2V6H10.8ZM2.39999 12C2.39999 17.292 6.70799 21.6 12 21.6C17.292 21.6 21.6 17.292 21.6 12C21.6 6.708 17.292 2.4 12 2.4C6.70799 2.4 2.39999 6.708 2.39999 12Z" />
                </svg>
            </div>
        )
    }

	return (
        <div onClick={handleClick} className={setClassList()}>
            <div className="icon">
                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6 4H4V3H2V6H18V3H16V4H14V3H6V4ZM18 8H2V18H18V8ZM14 1H6V0H4V1H2C0.89543 1 0 1.89543 0 3V18C0 19.1046 0.89543 20 2 20H18C19.1046 20 20 19.1046 20 18V3C20 1.89543 19.1046 1 18 1H16V0H14V1ZM5 12V10H7V12H5ZM9 12H11V10H9V12ZM13 12V10H15V12H13ZM5 14V16H7V14H5ZM11 16H9V14H11V16Z" />
                </svg>                
            </div>
            <div className="day">{props.text}</div>
            <div className="date">{props.day + "/" + props.month}</div>

            {
                props.fromWhere === "my-orders" ? (
                    addMealsPacket()
                ) : null
            }

        </div>
	)
}

export default DayChooser
