import React, { useEffect } from 'react'
import Menu from '../components/Menu'
import Header from '../components/Header'
import { withPaddingPage } from '../config/Util'
import Strings from '../config/Strings'
import AccountInfo from '../components/AccountInfo'
import AccountItem from '../components/AccountItem'
import { AccountService } from '../services/AccountService'
import { useHistory } from 'react-router-dom'
import ReactRoutes from '../config/ReactRoutes'

function Account(props) {

    withPaddingPage()

    const history = useHistory()

    useEffect(() => {

    }, [])

    function logOut() {
        AccountService.logOut()
            .then(response => {
                history.push(ReactRoutes.INDEX)
            })
            .catch(error => {
                console.log(error)
            })
    }

	return (
        <div>
            <Header text={Strings.ACCOUNT_PAGE} />
            <AccountInfo
                name={props.sessionData.name}
                lastName={props.sessionData.lastName}
                telephone={props.sessionData.telephone}
                email={props.sessionData.email}
                companyName={props.sessionData.companyName}
            />

            <AccountItem onClick={ () => history.push(ReactRoutes.SERPICA_HOME_SETTINGS) } text={Strings.SERPICA_HOME_SETTINGS} icon="home" />
            <AccountItem onClick={ () => history.push(ReactRoutes.CHANGE_PASSWORD) } text={Strings.CHANGE_PASSWORD} icon="password" />
            <AccountItem onClick={ () => history.push(ReactRoutes.CHANGE_EMAIL) } text={Strings.CHANGE_EMAIL} icon="email" />
            <AccountItem onClick={ () => history.push(ReactRoutes.CHANGE_SECTOR) } text={Strings.CHANGE_SECTOR} icon="sector" />
            <AccountItem onClick={ () => history.push(ReactRoutes.CHANGE_COMPANY) } text={Strings.CHANGE_COMPANY} icon="company" danger />
            <AccountItem onClick={ () => history.push(ReactRoutes.DEACTIVATE_ACCOUNT) } text={Strings.DEACTIVATE_ACCOUNT} icon="delete" danger />
            <AccountItem onClick={logOut} text={Strings.LOGOUT} icon="logout" />

            <Menu active="account" />
        </div>
	)
}

export default Account
