import React, { useEffect } from 'react'

function AccountInfo(props) {

    useEffect(() => {

    }, [])

    function setNameInitials() {
        if (props.name !== undefined && props.lastName !== undefined) {
            return props.name[0] + ". " + props.lastName[0] + "."
        }
    }

    function setFormattedName() {
        if (props.name !== undefined && props.lastName !== undefined) {
            return props.name.charAt(0).toUpperCase() +
                props.name.slice(1).toLowerCase() +
                " " +
                props.lastName.charAt(0).toUpperCase() +
                props.lastName.slice(1).toLowerCase()
        }
    }

    function setFormattedTelephoneNum() {
        if (props.telephone !== undefined) {
            let telephone = props.telephone.split("")

            let formatedTelephone = telephone[0] + telephone[1] + telephone[2] + " "

            telephone.shift()
            telephone.shift()
            telephone.shift()

            if (telephone.length === 6) {
                formatedTelephone += telephone[0] + telephone[1] + telephone[2] + " " + telephone[3] + telephone[4] + telephone[5]
            } else if (telephone.length === 7) {
                formatedTelephone += telephone[0] + telephone[1] + telephone[2] + " " + telephone[3] + telephone[4] + " " + telephone[5] + telephone[6]
            } else {
                formatedTelephone += telephone.join("")
            }
            return formatedTelephone
        }
    }

    function setFormattedEmail() {
        if (props.email !== undefined) {
            if (props.email.length > 18) {
                return props.email.replace("@", " @")
            }
            return props.email
        }
    }

	return (
    	<div className="account-info show-animation">
            <div className="initials">{setNameInitials()}</div>
            <div className="info">
                <div className="name">{setFormattedName()}</div>
                <div className="telephone">{setFormattedTelephoneNum()}</div>
                <div className="email">{setFormattedEmail()}</div>
                <div className="company">{props.companyName}</div>
            </div>
        </div>
	)
}

export default AccountInfo
