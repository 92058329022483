import React from 'react'

function Loader(props) {

	return (
        <div className={ props.fullScreen ? "loader full-screen" : "loader" }>
            <div className="wrapper">
                <div className="bouncer1"></div>
                <div className="bouncer2"></div>
            </div>
        </div>
	)
}

export default Loader
