import React, { useState, useEffect } from 'react'
import { noPaddingPage } from '../config/Util'
import { useHistory } from 'react-router-dom'
import Dialog from '../components/Dialog'
import Strings from '../config/Strings'
import ReactRoutes from '../config/ReactRoutes'
import { LogInRegisterService } from '../services/LogInRegisterService'

function ForgottenPassword(props) {

	noPaddingPage()

	const history = useHistory()

	const [email, setEmail] = useState("")
	const [telephone, setTelephone] = useState("")

	const [showDialog, setShowDialog] = useState(false)
	const [dialogData, setDialogData] = useState({})

	useEffect(() => {

    }, [])
    
    function requestNewPassword(e) {
        if (validateInput()) {
            let targetClassList = e.target.classList;
            targetClassList.add("spinner")
            
            if (telephone.length !== 0) {
                LogInRegisterService.resetPasswordViaTelephone(telephone)
                    .then(response => {
                        if (response === "zahtevupisan") {
                            setDialogData({
                                title: Strings.FORGOTTEN_PASSWORD_SUCCESSFUL_TELEPPHONE
                            })
                            setShowDialog(true)
                        } else {
                            setDialogData({
                                title: response
                            })
                            setShowDialog(true)
                        }
                        targetClassList.remove("spinner")
                    })
                    .catch(error => {
                        console.log(error)
                        targetClassList.remove("spinner")
                    })
            } else if (email.length !==0) {
                LogInRegisterService.resetPasswordViaEmail(email)
                    .then(response => {
                        if (response === "zahtevupisan") {
                            setDialogData({
                                title: Strings.FORGOTTEN_PASSWORD_SUCCESSFUL_EMAIL
                            })
                            setShowDialog(true)
                        } else {
                            setDialogData({
                                title: response
                            })
                            setShowDialog(true)
                        }
                        targetClassList.remove("spinner")
                    })
                    .catch(error => {
                        console.log(error)
                        targetClassList.remove("spinner")
                    })
            }
        }    
    }

    function validateInput() {
        let valid = true

        if (!email && !telephone) {
            valid = false
            setDialogData({
                title: Strings.ERROR_MUST_FILL_ONE_FIELD
            })
        }

        setShowDialog(!valid)
        return valid
    }

	return (
		<div className="show-animation" style={{ overflow: "auto" }}>
			<img src="assets/img/logo.png" className="logo" alt="logo" />

			<input disabled={email.length !== 0} onChange={ (e) => setTelephone(e.target.value) } className="input" placeholder="Vaš broj telefona" type="tel" />

            <p className="text">ili</p>

			<input disabled={telephone.length !== 0} onChange={ (e) => setEmail(e.target.value) } className="input" placeholder="Vaša E-mail adresa" type="email" />

			<button onClick={requestNewPassword} className="btn btn-green">Zahtevaj novu lozinku</button>

			<button onClick={ () => history.push(ReactRoutes.LOGIN) } className="btn mt3rem">Prijavi se</button>

			{
				showDialog ? <Dialog primaryText={Strings.OK} primaryAction={ () => setShowDialog(false) } data={dialogData} /> : null
			}

		</div>
	)
}

export default ForgottenPassword