import React, { useEffect } from 'react'

function Dialog(props) {

    useEffect(() => {
        document.body.classList.add("no-scroll")
        return () => {
            document.body.classList.remove("no-scroll")
        }
    }, [])

    function setSecondary() {
        if (props.secondaryText !== undefined && props.secondaryAction !== undefined) {
            return (
                <div onClick={ () => props.secondaryAction() } className="action">{props.secondaryText}</div>
            )
        }
    }

    function setPrimary() {
        if (props.primaryText !== undefined && props.primaryAction !== undefined) {
            return (
                <div onClick={ () => props.primaryAction() } className="action primary">{props.primaryText}</div>
            )
        }
    }

	return (
        <div className="backdrop">
            <div className="dialog">
                {
                    props.data.title !== undefined ? 
                        (
                            <div className="title">{ props.data.title }</div>
                        ) : null
                }
                
                {
                    props.data.message !== undefined ?
                        (
                            <div dangerouslySetInnerHTML={{ __html: props.data.message }} className="text">
                            </div>
                        ) : null
                }

                <div className="actions">
                    {
                        setSecondary()
                    }

                    {
                        setPrimary()
                    }
                </div>
            </div>
        </div>
	)
}

export default Dialog
