import React from 'react'
import {formatCurrency} from "../config/Util";

function HistoryWeekTotal({firstDate, lastDate, total}) {
    function setDays() {
        if (firstDate === undefined || lastDate === undefined)
            return

        let [, firstMonth, firstDay] = firstDate.split("-")
        let [, lastMonth, lastDay] = lastDate.split("-")

        return firstDay + '/' + firstMonth + ' - ' + lastDay + '/' + lastMonth
    }

    return (
        <div className="history-week-total show-animation">
            <div className="days">{setDays()}</div>
            <div className="total">{formatCurrency(total)}</div>
        </div>
    )
}

export default HistoryWeekTotal
