import React, {useEffect} from 'react'

export default function MealOption(props) {

    useEffect(() => {

    }, [])

    function handleClick() {
        if (props.selectOption !== undefined) {
            if (parseInt(props.selectedOption) === parseInt(props.id)) {
                props.selectOption(undefined, undefined)
            } else {
                props.selectOption(parseInt(props.id), props.price)
            }
        }
    }

    function calculateDessertDiscount() {
        if (props.percentageToShow === undefined) {
            return props.price
        } else {
            return Math.floor((props.price / 100) * parseInt(props.percentageToShow))
        }
    }

    function weightLabel() {
        if (props.weightLabel === undefined) {
            return
        }

        return <div className="weight">{props.weightLabel}</div>
    }

    function setClassList() {
        let classes = ["option"]

        if (parseInt(props.selectedOption) === parseInt(props.id)) {
            classes.push("selected")
        }

        if (props.weightLabel !== undefined) {
            classes.push("with-weight")
        }

        return classes.join(" ")
    }

    return (
        <div onClick={handleClick} className={setClassList()}>
            <span>{props.name}</span>
            <span>{calculateDessertDiscount()} rsd</span>
            {weightLabel()}
        </div>
    )
}
