import React, { useEffect, useState } from 'react'
import Strings from '../config/Strings'

function DeliveryTimeDialog(props) {

    const [deliveryTime, setDeliveryTime] = useState(null)

    useEffect(() => {
        document.body.classList.add("no-scroll")
        return () => {
            document.body.classList.remove("no-scroll")
        }
    }, [])

	return (
        <div className="backdrop">
            <div className="dialog">
                <div className="title">{Strings.CHOOSE_DELIVERY_TIME}</div>

                <div className="text">
                    <div className="check-wrapper">
                        <input onChange={ () => setDeliveryTime(0) } className="check" name="timeOfDelivery" type="radio" id="firstDeliveryTime" />
                        <label htmlFor="firstDeliveryTime">12:00h - 12:30h</label>
                    </div>
                    <div className="check-wrapper">
                        <input onChange={ () => setDeliveryTime(1) } className="check" name="timeOfDelivery" type="radio" id="secondDeliveryTime" />
                        <label htmlFor="secondDeliveryTime">12:30h - 13:00h</label>
                    </div>
                    <div className="check-wrapper">
                        <input onChange={ () => setDeliveryTime(2) } className="check" name="timeOfDelivery" type="radio" id="thirdDeliveryTime" />
                        <label htmlFor="thirdDeliveryTime">13:00h - 13:30h</label>
                    </div>
                </div>

                <div className="actions">
                    <div onClick={ () => props.closeDialog() } className="action">{Strings.CANCEL}</div>
                    {
                        deliveryTime !== null ? <div onClick={ () => props.primaryAction(deliveryTime) } className="action primary">{props.isEdit ? Strings.SAVE : Strings.MAKE_ORDER}</div> : null
                    }
                </div>
            </div>
        </div>
	)
}

export default DeliveryTimeDialog
