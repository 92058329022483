import React, { useState, useEffect } from 'react'
import Menu from '../components/Menu'
import Header from '../components/Header'
import { withPaddingPage, getDateAsYearMonthDay, getDateAsYYYYMMDDString } from '../config/Util'
import Loader from '../components/Loader'
import Strings from '../config/Strings'
import DayChooser from '../components/DayChooser'
import { OrderService } from '../services/OrderService'
import DayItem from '../components/DayItem'
import { HomeOrderService } from '../services/HomeOrderService'
import DayItemsWithDelivery from '../components/DayItemsWithDelivery'

function MyOrders(props) {

    withPaddingPage()

    const [isLoaded, setIsLoaded] = useState(false)
    const [myOrders, setMyOrders] = useState([])
    const [myHomeOrders, setMyHomeOrders] = useState([])
    const [reRender, setReRender] = useState(false)

    useEffect(() => {
        let date = new Date()
        let increaseDay = (date.getHours() >= props.sessionData.finalTime) ? 1 : 0

        date = new Date(Date.now() + increaseDay * 24 * 60 * 60 * 1000)

        Promise.all([
            OrderService.getMyOrders(date),
            HomeOrderService.getMyOrders(date)
        ])
        .then(([myOrders, myHomeOrders]) => {
            setMyOrders(myOrders)
            setMyHomeOrders(myHomeOrders)
            setIsLoaded(true)
        })
        .catch(error => {
            console.log(error)
        })
    }, [reRender])

    function initializeContent() {
        let dayNames = Strings.DAY_NAMES_ARRAY
        let date = new Date()
        let increaseDay = (date.getHours() >= props.sessionData.finalTime) ? 1 : 0
    
        let output = []
    
        for (let i = 0; i < 5; i ++) {
            date = new Date(Date.now() + (i + increaseDay) * 24 * 60 * 60 * 1000)

            let [year, month, day] = getDateAsYearMonthDay(date)
    
            output.push(
                <DayChooser
                    fromWhere="my-orders"
                    key={i}
                    homeDelivery={props.sessionData.homeDelivery}
                    text={(i === 0 && increaseDay === 0) ? Strings.TODAY : dayNames[date.getDay()]}
                    day={day}
                    month={month}
                    year={year}
                    disabled={isDateDisabled(date)}
                />
            )

            for(let order of myOrders) {
                if (Date.parse(order[2]) === Date.parse(getDateAsYYYYMMDDString(date))) {
                    output.push(
                        <DayItem
                            onDelete={() => setReRender(!reRender)}
                            key={order[0]}
                            homeDelivery={props.sessionData.homeDelivery}
                            isDisabled={props.sessionData.homeDelivery}
                            orderId={order[0]}
                            text={order[1]}
                            total={order[3]}
                            dayName={(i === 0 && increaseDay === 0) ? Strings.TODAY : dayNames[date.getDay()]}
                            day={day}
                            month={month}
                            year={year}
                        />
                    )
                }
            }

            let deliveryTime0 = { items: [], total: 0, deliveryTime: null }
            let deliveryTime1 = { items: [], total: 0, deliveryTime: null }
            let deliveryTime2 = { items: [], total: 0, deliveryTime: null }

            for(let order of myHomeOrders) {
                if (Date.parse(order[2]) === Date.parse(getDateAsYYYYMMDDString(date))) {
                    let dayItem = <DayItem
                            onDelete={() => setReRender(!reRender)}
                            key={"homeOrder" + order[0]} // increase id to avoid conflict
                            homeDelivery={props.sessionData.homeDelivery}
                            isDisabled={!props.sessionData.homeDelivery}
                            disableAnimation={true}
                            orderId={order[0]}
                            text={order[1]}
                            total={order[3]}
                            dayName={(i === 0 && increaseDay === 0) ? Strings.TODAY : dayNames[date.getDay()]}
                            day={day}
                            month={month}
                            year={year}
                        />

                    if (parseInt(order[5]) === 0) {
                        deliveryTime0.items.push(dayItem)
                        deliveryTime0.deliveryTime = order[4]
                        deliveryTime0.total += order[3]
                    } else if (parseInt(order[5]) === 1) {
                        deliveryTime1.items.push(dayItem)
                        deliveryTime1.deliveryTime = order[4]
                        deliveryTime1.total += order[3]
                    } else if (parseInt(order[5]) === 2) {
                        deliveryTime2.items.push(dayItem)
                        deliveryTime2.deliveryTime = order[4]
                        deliveryTime2.total += order[3]
                    }   
                }
            }

            if (deliveryTime0.items.length > 0) {
                output.push(
                    <DayItemsWithDelivery
                        key={"deliveryTime0" + i}
                        isHomeDelivery={props.sessionData.homeDelivery}
                        deliveryTime={deliveryTime0.deliveryTime}
                        total={deliveryTime0.total}
                        deliveryPrice={props.sessionData.deliveryPrice}
                        freeDeliveryPriceLimit={props.sessionData.freeDeliveryPriceLimit}
                    >
                        {deliveryTime0.items.map(item => item)}
                    </DayItemsWithDelivery>
                )
            }

            if (deliveryTime1.items.length > 0) {
                output.push(
                    <DayItemsWithDelivery
                        key={"deliveryTime1" + i}
                        isHomeDelivery={props.sessionData.homeDelivery}
                        deliveryTime={deliveryTime1.deliveryTime}
                        total={deliveryTime1.total}
                        deliveryPrice={props.sessionData.deliveryPrice}
                        freeDeliveryPriceLimit={props.sessionData.freeDeliveryPriceLimit}
                    >
                        {deliveryTime1.items.map(item => item)}
                    </DayItemsWithDelivery>
                )
            }

            if (deliveryTime2.items.length > 0) {
                output.push(
                    <DayItemsWithDelivery
                        key={"deliveryTime2" + i}
                        isHomeDelivery={props.sessionData.homeDelivery}
                        deliveryTime={deliveryTime2.deliveryTime}
                        total={deliveryTime2.total}
                        deliveryPrice={props.sessionData.deliveryPrice}
                        freeDeliveryPriceLimit={props.sessionData.freeDeliveryPriceLimit}
                    >
                        {deliveryTime2.items.map(item => item)}
                    </DayItemsWithDelivery>
                )
            }
        }

        return output
    }
    
    function isDateDisabled(date) {
        return (date.getDay() === 0 || date.getDay() === 6) ? true : false
    }

    function setLogo() {
        if (props.sessionData.homeDelivery === undefined)
            return <img src="assets/img/logo.png" className="logo show-animation" alt="Logo" />
        
        if (props.sessionData.homeDelivery === true)
            return <img src="assets/img/logo-home.png" className="logo show-animation" alt="Logo" />

        if (props.sessionData.homeDelivery === false)
            return <img src="assets/img/logo-office.png" className="logo show-animation" alt="Logo" />
    }

	return (
        <div>
            <Header text={Strings.PAGE_MY_ORDERS} />

            {
                isLoaded ? (
                    <div>
                        {setLogo()}
                        {initializeContent()}
                    </div>
                ) : (
                    <Loader />
                )
            }

            <Menu active="my-orders" />
        </div>
	)
}

export default MyOrders
