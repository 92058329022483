import React, { useEffect } from 'react'
import Strings from '../config/Strings'

function AccountItem(props) {

    useEffect(() => {

    }, [])

    function setIcon() {
        switch (props.icon) {
            case "home":
                return (
                    <svg width="18" height="15" viewBox="0 0 18 15" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.4306 8.2955V13.6818C15.4306 14.0746 15.1069 14.4 14.7161 14.4H10.429V10.0909H7.57097V14.4H3.28387C2.89312 14.4 2.56936 14.0746 2.56936 13.6818V8.2955C2.56936 8.27306 2.58052 8.25061 2.58052 8.22817L9 2.90918L15.4195 8.22817C15.4306 8.25061 15.4306 8.27306 15.4306 8.2955ZM17.9203 7.52122L17.2281 8.35161C17.1723 8.41894 17.083 8.46382 16.9936 8.47504H16.9602C16.8708 8.47504 16.7927 8.4526 16.7257 8.39649L9 1.92168L1.2743 8.39649C1.19615 8.4526 1.10683 8.48627 1.00635 8.47504C0.917038 8.46382 0.827724 8.41894 0.771902 8.35161L0.0797148 7.52122C-0.0430927 7.37534 -0.0207641 7.13968 0.124372 7.01625L8.15151 0.294565C8.62041 -0.0981882 9.37959 -0.0981882 9.84849 0.294565L12.5726 2.58375V0.395558C12.5726 0.193571 12.7289 0.0364699 12.9298 0.0364699H15.0734C15.2743 0.0364699 15.4306 0.193571 15.4306 0.395558V4.97393L17.8756 7.01625C18.0208 7.13968 18.0431 7.37534 17.9203 7.52122Z" />
                    </svg>
                )

            case "password":
                return (
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8 9C8 5.13401 11.134 2 15 2C18.866 2 22 5.13401 22 9C22 12.866 18.866 16 15 16H13V18H11V20H9V22H2V16.5858L8.14801 10.4378C8.04995 9.96847 8 9.48731 8 9ZM11 16V14H15C17.7614 14 20 11.7614 20 9C20 6.23858 17.7614 4 15 4C12.2386 4 10 6.23858 10 9C10 9.49863 10.0727 9.98638 10.2141 10.4529L10.3879 11.0263L4 17.4142V20H7V18H9V16H11ZM13 9C13 10.1046 13.8954 11 15 11C16.1046 11 17 10.1046 17 9C17 7.89543 16.1046 7 15 7C13.8954 7 13 7.89543 13 9Z" />
                    </svg>
                )

            case "email":
                return (
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M20 4H4C2.90002 4 2.01001 4.89999 2.01001 6L2 18C2 19.1 2.90002 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.89999 21.1 4 20 4ZM4 8L12 13L20 8V18H4V8ZM4 6L12 11L20 6H4Z" />
                    </svg>
                )

            case "sector":
                return (
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 8.98528 8.98528 11 6.5 11ZM2 17.5C2 19.9853 4.01472 22 6.5 22C8.98528 22 11 19.9853 11 17.5C11 15.0147 8.98528 13 6.5 13C4.01472 13 2 15.0147 2 17.5ZM13 17.5C13 19.9853 15.0147 22 17.5 22C19.9853 22 22 19.9853 22 17.5C22 15.0147 19.9853 13 17.5 13C15.0147 13 13 15.0147 13 17.5ZM13 6.5C13 8.98528 15.0147 11 17.5 11C19.9853 11 22 8.98528 22 6.5C22 4.01472 19.9853 2 17.5 2C15.0147 2 13 4.01472 13 6.5ZM17.5 9C18.8807 9 20 7.88071 20 6.5C20 5.11929 18.8807 4 17.5 4C16.1193 4 15 5.11929 15 6.5C15 7.88071 16.1193 9 17.5 9ZM9 6.5C9 7.88071 7.88071 9 6.5 9C5.11929 9 4 7.88071 4 6.5C4 5.11929 5.11929 4 6.5 4C7.88071 4 9 5.11929 9 6.5ZM17.5 20C18.8807 20 20 18.8807 20 17.5C20 16.1193 18.8807 15 17.5 15C16.1193 15 15 16.1193 15 17.5C15 18.8807 16.1193 20 17.5 20ZM9 17.5C9 18.8807 7.88071 20 6.5 20C5.11929 20 4 18.8807 4 17.5C4 16.1193 5.11929 15 6.5 15C7.88071 15 9 16.1193 9 17.5Z"/>
                    </svg>
                )

            case "company":
                return (
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.5 7L12 2L21.5 7V9H2.5V7ZM12 4.26001L17.2101 7H6.79004L12 4.26001ZM5 11H7V18H5V11ZM13 11V18H11V11H13ZM2.5 20V22H21.5V20H2.5ZM17 11H19V18H17V11Z" />
                    </svg>
                )

            case "delete":
                return (
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9 1H15C16.1046 1 17 1.89543 17 3V4H20C21.1046 4 22 4.89543 22 6V8C22 9.10457 21.1046 10 20 10H19.9199L19 21C19 22.1046 18.1046 23 17 23H7C5.89543 23 5 22.1046 5.00345 21.083L4.07987 10H4C2.89543 10 2 9.10457 2 8V6C2 4.89543 2.89543 4 4 4H7V3C7 1.89543 7.89543 1 9 1ZM4 6H7H17H20V8H4V6ZM6.08649 10H17.9132L17.0035 20.917L17 21H7L6.08649 10ZM15 3V4H9V3H15ZM13.4142 15L15.7071 17.2929L14.2929 18.7071L12 16.4142L9.70711 18.7071L8.29289 17.2929L10.5858 15L8.29289 12.7071L9.70711 11.2929L12 13.5858L14.2929 11.2929L15.7071 12.7071L13.4142 15Z" />
                    </svg>
                )
            
            case "logout":
                return (
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16 11L5.41424 11L8.70714 7.70711L7.29292 6.29289L1.58582 12L7.29292 17.7071L8.70714 16.2929L5.41424 13L16 13V11ZM20 3L11 3V5L20 5L20 19L11 19V21L20 21C21.1046 21 22 20.1046 22 19L22 5C22 3.89543 21.1046 3 20 3Z" />
                    </svg>
                )
        }
    }

    function handleClick() {
        props.onClick()
    }

	return (
    	<div onClick={handleClick} className={props.danger === undefined ? "account-item show-animation" : "account-item danger show-animation"}>
            <div className="icon-left">
                {setIcon()}               
            </div>

            <div className="text">{props.text}{props.text === Strings.SERPICA_HOME_SETTINGS ? <span style={{marginLeft: "5px", fontWeight: "bold", color: "white", backgroundColor: "var(--color-green-20p-darker)", padding: "2px 5px", borderRadius: "5px"}}>Novo!</span> : null}</div>

            <div className="icon-right">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.70504 6L8.29504 7.41L12.875 12L8.29504 16.59L9.70504 18L15.705 12L9.70504 6Z" />
            </svg>
            </div>
        </div>
	)
}

export default AccountItem
