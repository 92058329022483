import React, { useState, useEffect } from 'react'

function Header(props) {

    const [classList, setClassList] = useState("header")

    useEffect(() => {
        window.onscroll = function() {
            if (window.pageYOffset === 0) {
                setClassList("header")
            } else {
                if (classList !== "header shadow") {
                    setClassList("header shadow")
                }
            }
        }

        return () => {
            window.onscroll = null
        }
    }, [])

	return (
        <div className={classList}>
            <div className="spacer"></div>
            <div className="content">
                {
                    props.back !== undefined ?
                        (
                            <div onClick={ props.back } className="back">
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" />
                                </svg>    
                            </div>
                        ) : null
                }

                { props.text }
            </div>
        </div>
	)
}

export default Header
