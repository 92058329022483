import React, { useEffect, useState } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import { withPaddingPage } from '../../config/Util'
import Strings from '../../config/Strings'
import AccountInfo from '../../components/AccountInfo'
import { useHistory } from 'react-router-dom'
import Dialog from '../../components/Dialog'
import ReactRoutes from '../../config/ReactRoutes'
import { AccountService } from '../../services/AccountService'

function HomeSettings(props) {

    withPaddingPage()

    const [streetAddress, setStreetAddress] = useState("")
    const [buildingLevel, setBuildingLevel] = useState("")
    const [doorNumber, setDoorNumber] = useState("")
    const [yourPassword, setYourPassword] = useState("")
    const [homeDelivery, setHomeDelivery] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [dialogData, setDialogData] = useState({})
    const [showErrorDialog, setShowErrorDialog] = useState(false)
    const [errorDialogData, setErrorDialogData] = useState({})

    const history = useHistory()

    useEffect(() => {
    }, [])

    useEffect(() => {
        setAddressParts()
        setCheckBoxState()
    }, [props.sessionData])

    function changeDeliveryAddress(e) {
        if (validateInput()) {
            let targetClassList = e.target.classList;
            targetClassList.add("spinner")
            
            AccountService.changeDeliveryAddress(yourPassword, streetAddress, buildingLevel, doorNumber, homeDelivery)
                .then(response => {
                    if (response === "novaadresadostaveupisana") {
                        setDialogData({
                            title: Strings.DELIVERY_ADDRESS_CHANGED
                        })
                        setShowDialog(true)
                        props.triggerRefreshSessionData()
                    } else {
                        setErrorDialogData({
                            title: response
                        })
                        setShowErrorDialog(true)
                    }
                    targetClassList.remove("spinner")
                })
                .catch(error => {
                    console.log(error)
                    targetClassList.remove("spinner")
                })
        }
    }

    function validateInput() {
        let valid = true

        if (!yourPassword) {
            valid = false
            setErrorDialogData({
                title: Strings.ERROR_MUST_FILL_PASSWORD
            })
        }

        if (!streetAddress) {
            valid = false
            setErrorDialogData({
                title: Strings.ERROR_MUST_FILL_STREET_ADDRESS
            })
        }

        setShowErrorDialog(!valid)
        return valid
    }

    function setAddressParts() {
        if (props.sessionData.deliveryAddress === undefined)
            return

        if (props.sessionData.deliveryAddress === "") {
            setStreetAddress("")
            setBuildingLevel("")
            setDoorNumber("")
        } else {
            let [streetAddressFromAddress, buildingLevelFromAddress, doorNumberFromAddress] = props.sessionData.deliveryAddress.split("#")

            setStreetAddress(streetAddressFromAddress)
            setBuildingLevel(buildingLevelFromAddress)
            setDoorNumber(doorNumberFromAddress)
        }
    }

    function setCheckBoxState() {
        if (props.sessionData.homeDelivery === undefined)
            return

        setHomeDelivery(props.sessionData.homeDelivery)
    }

	return (
        <div>
            <Header back={ () => history.goBack() } text={Strings.SERPICA_HOME_SETTINGS} />
            <AccountInfo
                name={props.sessionData.name}
                lastName={props.sessionData.lastName}
                telephone={props.sessionData.telephone}
                email={props.sessionData.email}
                companyName={props.sessionData.companyName}   
            />

            <p className="text show-animation">{Strings.TEXT_SERPICA_HOME}</p>

            <p className="card show-animation" dangerouslySetInnerHTML={{ __html: Strings.TEXT_SERPICA_HOME_DELIVERY_INFO }}></p>

            <p className="card show-animation" dangerouslySetInnerHTML={{ __html: Strings.TEXT_SERPICA_HOME_DELIVERY_PRICE.replaceAll('{deliveryPrice}', props.sessionData.deliveryPrice).replaceAll('{freeDeliveryPriceLimit}', props.sessionData.freeDeliveryPriceLimit) }}></p>

            <p className="card show-animation" dangerouslySetInnerHTML={{ __html: Strings.TEXT_SERPICA_HOME_DELIVERY_POLICY }}></p>

            <input onChange={ (e) => setStreetAddress(e.target.value) } value={streetAddress} className="input show-animation" placeholder="Ulica i broj" type="text" />
			<input onChange={ (e) => setBuildingLevel(e.target.value) } value={buildingLevel} className="input show-animation" placeholder="Sprat (možete izostaviti)" type="text" />
            <input onChange={ (e) => setDoorNumber(e.target.value) } value={doorNumber} className="input show-animation" placeholder="Stan (možete izostaviti)" type="text" />
            <input onChange={ (e) => setYourPassword(e.target.value) } className="input show-animation" placeholder="Vaša lozinka" type="password" />
            <div className="check-wrapper show-animation">
                <input onChange={ (e) => setHomeDelivery(true) } className="check" checked={homeDelivery} name="whereToDeliver" type="radio" id="homeDeliveryRadio" />
                <label htmlFor="homeDeliveryRadio">Želim dostavu na kućnu adresu</label>
            </div>
            <div className="check-wrapper show-animation">
                <input onChange={ (e) => setHomeDelivery(false) } className="check" checked={!homeDelivery} name="whereToDeliver" type="radio" id="officeDeliveryRadio" />
                <label htmlFor="officeDeliveryRadio">Želim dostavu na adresu firme</label>
            </div>

            <button onClick={changeDeliveryAddress} className="btn btn-green mt2rem show-animation">{Strings.SAVE}</button>

            <Menu active="account" />

            {
				showDialog ? <Dialog primaryText={Strings.OK} primaryAction={ () => history.push(ReactRoutes.ACCOUNT) } data={dialogData} /> : null
			}

            {
				showErrorDialog ? <Dialog primaryText={Strings.OK} primaryAction={ () => setShowErrorDialog(false) } data={errorDialogData} /> : null
			}
        </div>
	)
}

export default HomeSettings
