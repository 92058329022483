import React from 'react'
import { useHistory } from 'react-router-dom'
import ReactRoutes from '../config/ReactRoutes'

function Menu(props) {

    const history = useHistory()

    function setActive(menuItem) {
        return menuItem === props.active ? "item active" : "item"
    }

	return (
        <div className="menu">
            <div className="content">
                <div onClick={ () => history.push(ReactRoutes.HOME) } className={ setActive("home") }>
                    <svg width="18" height="15" viewBox="0 0 18 15" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.4306 8.2955V13.6818C15.4306 14.0746 15.1069 14.4 14.7161 14.4H10.429V10.0909H7.57097V14.4H3.28387C2.89312 14.4 2.56936 14.0746 2.56936 13.6818V8.2955C2.56936 8.27306 2.58052 8.25061 2.58052 8.22817L9 2.90918L15.4195 8.22817C15.4306 8.25061 15.4306 8.27306 15.4306 8.2955ZM17.9203 7.52122L17.2281 8.35161C17.1723 8.41894 17.083 8.46382 16.9936 8.47504H16.9602C16.8708 8.47504 16.7927 8.4526 16.7257 8.39649L9 1.92168L1.2743 8.39649C1.19615 8.4526 1.10683 8.48627 1.00635 8.47504C0.917038 8.46382 0.827724 8.41894 0.771902 8.35161L0.0797148 7.52122C-0.0430927 7.37534 -0.0207641 7.13968 0.124372 7.01625L8.15151 0.294565C8.62041 -0.0981882 9.37959 -0.0981882 9.84849 0.294565L12.5726 2.58375V0.395558C12.5726 0.193571 12.7289 0.0364699 12.9298 0.0364699H15.0734C15.2743 0.0364699 15.4306 0.193571 15.4306 0.395558V4.97393L17.8756 7.01625C18.0208 7.13968 18.0431 7.37534 17.9203 7.52122Z" />
                    </svg>
                </div>
                <div onClick={ () => history.push(ReactRoutes.MY_ORDERS) } className={ setActive("my-orders") }>
                    <svg width="15" height="18" viewBox="0 0 15 18" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.42857 0.642857C6.42857 0.291295 6.13728 0 5.78571 0C5.43415 0 5.14286 0.291295 5.14286 0.642857V4.82143C5.14286 5.17299 4.85156 5.46429 4.5 5.46429C4.14844 5.46429 3.85714 5.17299 3.85714 4.82143V0.642857C3.85714 0.291295 3.56585 0 3.21429 0C2.86272 0 2.57143 0.291295 2.57143 0.642857V4.82143C2.57143 5.17299 2.28013 5.46429 1.92857 5.46429C1.57701 5.46429 1.28571 5.17299 1.28571 4.82143V0.642857C1.28571 0.291295 0.99442 0 0.642857 0C0.291295 0 0 0.291295 0 0.642857V7.07143C0 7.88505 0.522321 8.6183 1.28571 8.88951V16.7143C1.28571 17.4174 1.8683 18 2.57143 18H3.85714C4.56027 18 5.14286 17.4174 5.14286 16.7143V8.88951C5.90625 8.6183 6.42857 7.88505 6.42857 7.07143V0.642857ZM14.1429 0.642857C14.1429 0.291295 13.8516 0 13.5 0H10.9286C9.16071 0 7.71429 1.44643 7.71429 3.21429V11.25C7.71429 11.4208 7.86496 11.5714 8.03571 11.5714H10.2857V16.7143C10.2857 17.4174 10.8683 18 11.5714 18H12.8571C13.5603 18 14.1429 17.4174 14.1429 16.7143V0.642857Z" />
                    </svg>
                </div>
                <div onClick={ () => history.push(ReactRoutes.ORDERS_HISTORY) } className={ setActive("history") }>
                    <svg width="18" height="20" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.5 1.5L15 0L13.5 1.5L12 0L10.5 1.5L9 0L7.5 1.5L6 0L4.5 1.5L3 0L1.5 1.5L0 0V20L1.5 18.5L3 20L4.5 18.5L6 20L7.5 18.5L9 20L10.5 18.5L12 20L13.5 18.5L15 20L16.5 18.5L18 20V0L16.5 1.5ZM2 17.09V2.91H16V17.09H2ZM15 15V13H3V15H15ZM15 9V11H3V9H15ZM15 7V5H3V7H15Z" />
                    </svg>
                </div>
                <div onClick={ () => history.push(ReactRoutes.FEEDBACK) } className={ setActive("feedback") }>
                    <svg width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9 15.4286C9.16071 15.4286 9.32143 15.3683 9.44196 15.2478L15.6998 9.22098C15.7902 9.13058 18 7.11161 18 4.70089C18 1.75781 16.202 0 13.1987 0C11.4408 0 9.79353 1.38616 9 2.16964C8.20647 1.38616 6.55915 0 4.80134 0C1.79799 0 0 1.75781 0 4.70089C0 7.11161 2.20982 9.13058 2.29018 9.20089L8.55804 15.2478C8.67857 15.3683 8.83929 15.4286 9 15.4286Z" />
                    </svg>
                </div>
                <div onClick={ () => history.push(ReactRoutes.ACCOUNT) } className={ setActive("account") }>
                    <svg width="17" height="18" viewBox="0 0 17 18" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.9412 14.9648C16.9412 16.8633 15.6417 18 13.7286 18H3.21257C1.29947 18 0 16.8633 0 14.9648C0 12.3164 0.637701 8.25 4.1631 8.25C4.5361 8.25 6.1123 9.87891 8.47059 9.87891C10.8289 9.87891 12.4051 8.25 12.7781 8.25C16.3035 8.25 16.9412 12.3164 16.9412 14.9648ZM13.0909 4.5C13.0909 6.98438 11.0214 9 8.47059 9C5.91979 9 3.85027 6.98438 3.85027 4.5C3.85027 2.01562 5.91979 0 8.47059 0C11.0214 0 13.0909 2.01562 13.0909 4.5Z" />
                    </svg>
                </div>
            </div>
            <div className="spacer"></div>
        </div>
	)
}

export default Menu
