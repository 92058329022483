import React from 'react'
import {formatCurrency} from "../config/Util";

function HistoryItem(props) {

    function setDate() {
        if (props.date === undefined)
            return

        let [, month, day] = props.date.split("-")

        return day + "/" + month
    }

    function setDeliveryTime() {
        if (props.deliveryTime === undefined)
            return

        return <div className="delivery-time">{props.deliveryTime}</div>
    }

    function setClassList() {
        let classes = ["history-item"]

        if (props.isDisabled) {
            classes.push("disabled")
        }

        if (!props.disableAnimation) {
            classes.push("show-animation")
        }

        if (props.noShadow) {
            classes.push('no-shadow')
        }

        return classes.join(" ")
    }

    return (
        <div className={setClassList()}>

            {setDeliveryTime()}

            <div className="text">
                {props.name}
            </div>

            <div className="right">
                <div className="day">
                    <div className="icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6 4H4V3H2V6H18V3H16V4H14V3H6V4ZM18 8H2V18H18V8ZM14 1H6V0H4V1H2C0.89543 1 0 1.89543 0 3V18C0 19.1046 0.89543 20 2 20H18C19.1046 20 20 19.1046 20 18V3C20 1.89543 19.1046 1 18 1H16V0H14V1ZM5 12V10H7V12H5ZM9 12H11V10H9V12ZM13 12V10H15V12H13ZM5 14V16H7V14H5ZM11 16H9V14H11V16Z" />
                        </svg>
                    </div>
                    {setDate()}
                </div>
                <div className="total">
                    Ukupno: {formatCurrency(props.total)}
                </div>
            </div>
        </div>
    )
}

export default HistoryItem
