import React from 'react'
import { formatCurrency, addDeliveryPrice } from '../config/Util'

function DayItemsWithDelivery(props) {

	return (
        <div className={props.isHomeDelivery ? "day-items-with-delivery show-animation" : "day-items-with-delivery disabled show-animation"}>
            <div className="info">
                <div className="item">
                    {props.deliveryTime}
                </div>

                <div className="item">
                    Ukupno sa dostavom: {formatCurrency(addDeliveryPrice(props.total, props.deliveryPrice, props.freeDeliveryPriceLimit))}
                </div>
            </div>
            {props.children}
        </div>
	)
}

export default DayItemsWithDelivery
